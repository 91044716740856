import axios from 'axios';
import config from 'config';
// @ts-ignore
import { marked } from 'marked';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';

function craftDeliveryQuery(site) {
  return {
    query: `
      query($site: [String]!) {
        globalSet(site: $site, handle: "deliveryInfo") {
          ... on deliveryInfo_GlobalSet {
            name
            body
          }
        }
      }
    `,
    variables: {
      site
    }
  };
}

const productQuery = `
query products($url_key: String!){
	products(filter: { url_key: { in: [$url_key] } }) {
		items {
			sku
			name
		}
	}
}
`;

const bikeQuery = `
query products($sku: String!){
	products(filter: { sku: { in: [$sku] } }) {
		items {
			sku
			name
			subtitle
			uid
			option_id:uid
      image {
        url
      }
			uid
			... on BundleProduct {
			  linked_custom_colour
				items {
					uid
					option_id:uid
					title
					required
					section
					section_slug
					options {
						uid
						id
						price
						special_price
						is_default
						dependent_on
						hexcode
						product {
							sku
							name
							stock_status
              description {
                html
              }
              image {
                url
              }
              listing_custom_image {
                url
              }
							... on ConfigurableProduct {
								configurable_options {
									id
									label
									position
									use_default
									attribute_code
									values {
										value_index
										size_label(sku: $sku)
										store_label
										name:label
										uid
										sku:uid
									}
								}
								variants(sku: $sku) {
									product {
									stock_status
										id
										name
										sku
										description {
										  html
										}
										image {
                      url
                      label
                    }
                    listing_custom_image {
                      url
                    }
									}
									attributes {
										uid
										label
										code
										value_index
									}
									size_mapping(sku: $sku) {
										handlebar {
											parent
											child
										}
										stem {
											parent
											child
										}
									}
								}
							}
							image {
								url
							}
						}
					}
				}
			}
		}
	}
}
`;

async function fetchMagentoData(query: string, variables = {}, rootGetters) {
  try {
    const code = rootGetters['bike/getCode'];
    const axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        'Content-Currency': rootGetters['esw/getUserCurrency'],
        'ESW-Country': rootGetters['esw/getUserCountry'],
        'gcdn-force': 1,
        ...(code ? { code: code } : {})
      }
    };
    const url = config.stellateUrl;
    const res = await axios.post(url, { query, variables }, axiosConfig);
    if (res.data.errors && res.data.errors.length) {
      console.error(res.data.errors[0]);
      throw new Error('Error with graphql api:' + url);
    }
    return res.data.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function fetchCmsData(query, token = null) {
  const url = config.craftcmsUrl;
  const fullUrl = token ? url + `?token=${token}` : url;
  try {
    const res = await axios.post(fullUrl, query);
    if (res.data.errors && res.data.errors.length) {
      console.error(res.data.errors[0]);
      throw new Error('Error with craft graphql api');
    }
    return res.data.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const bikeStore = {
  namespaced: true,
  state: {
    bike: null,
    product: null,
    loading: false,
    errorMessage: '',
    deliveryInfo: null,
    code: null
  },
  mutations: {
    SET_PRODUCT(state, product) {
      state.product = product;
    },
    SET_CODE(state, code) {
      state.code = code;
    },
    SET_BIKE(state, bike) {
      state.bike = bike;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_ERROR_MESSAGE(state, errorMessage) {
      state.errorMessage = errorMessage;
    },
    SET_DELIVERY_INFO(state, deliveryInfo) {
      state.deliveryInfo = deliveryInfo;
    }
  },
  actions: {
    async fetchProduct({ commit, rootGetters }, url_key) {
      try {
        commit('SET_ERROR_MESSAGE', '');
        commit('SET_LOADING', true);
        const variables = {
          url_key
        };
        const query = productQuery;
        const magentoRes = await fetchMagentoData(
          query,
          variables,
          rootGetters
        );
        const storeCode = currentStoreView().storeCode;
        const site = storeCode === 'de_de_de' ? 'ribbleDe' : 'default';
        const craftRes = await fetchCmsData(craftDeliveryQuery(site));
        const deliveryBody =
          craftRes && craftRes.globalSet ? craftRes.globalSet.body : '';
        const deliveryInfo = deliveryBody ? marked.parse(deliveryBody) : null;
        const product = magentoRes.products.items[0];
        commit('SET_PRODUCT', product);
        commit('SET_DELIVERY_INFO', deliveryInfo);
        return product;
      } catch (error) {
        console.error(error);
        if (error instanceof Error) {
          commit('SET_ERROR_MESSAGE', error.message);
        }
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async fetchBike({ commit, getters, rootGetters }) {
      try {
        if (!getters.getProductSku) {
          throw new Error(
            `No bike product found from product graphql call ${productQuery}`
          );
        }
        commit('SET_LOADING', true);
        const variables = {
          sku: getters.getProductSku
        };
        const query = bikeQuery;
        const res = await fetchMagentoData(query, variables, rootGetters);
        commit('SET_BIKE', res);
        return getters.getBikeOptions;
      } catch (error) {
        console.error(error);
        if (error instanceof Error) {
          commit('SET_ERROR_MESSAGE', error.message);
        }
      } finally {
        commit('SET_LOADING', false);
      }
    },
    setColourOption: ({ commit, rootState, rootGetters }) => {
      if (
        rootState.bike.bike.products.items &&
        rootState.bike.bike.products.items.length > 0
      ) {
        const items = rootState.bike.bike.products.items[0].items;
        const sizeOption = items.find((i) => i.section === 1);
        const hasCustomColors = rootGetters['color/getColors']

        // Build colourways option from the sub-assembly
        if (
          (rootState.color &&
            rootState.color.color &&
            rootState.color.color.length > 0) ||
          (sizeOption.options && sizeOption.options.length)
        ) {
          rootState.bike.bike.products.items[0].items.push({
            uid: 'colour-option',
            title: 'Colour',
            //hide from the UI, still populate selectedOptions
            included:sizeOption.options.length === 1 && !hasCustomColors,
            required: true,
            option_id: 1234,
            section: 1234,
            options: sizeOption.options
          });
        }

      }
    },
    setUrl: ({ commit }, url) => {
      commit('SET_URL', url);
    },
    setCode: ({ commit }, code) => {
      commit('SET_CODE', code);
    }
  },
  getters: {
    getBike: (state) => state.bike,
    getBikeOptions: (state, getters, rootState) => {
      const titleMap = {
        1: 'Size',
        7: 'Wheelset',
        10: 'Handlebar',
        12: 'Stem',
        14: 'Saddle',
        25: 'Pedals',
        1234: 'Colour'
      };
      const sortBy = [
        'Colour',
        'Wheelset',
        'Handlebar',
        'Stem',
        'Saddle',
        'Pedals',
        'Size'
      ];
      let bikeOptions = [];
      const hiddenOptions = ['Stem'];

      if (
        state.bike &&
        state.bike.products &&
        state.bike.products.items &&
        state.bike.products.items.length > 0
      ) {
        bikeOptions = state.bike.products.items[0].items
          .map((item) => ({
            ...item,
            title: titleMap[item.section],
            hidden: hiddenOptions.includes(titleMap[item.section])
          }))
          .filter(
            (item) => item.options.length > 0 && sortBy.includes(item.title)
          )
          .sort((a, b) => {
            const indexA = sortBy.indexOf(a.title);
            const indexB = sortBy.indexOf(b.title);
            return indexA - indexB;
          });
      }
      return bikeOptions;
    },
    getRequiredBikeOptions: (state, getters) => {
      return getters.getBikeOptions.filter((o) => o.required);
    },
    getSectionItemsLength: (state, getters) => {
      return getters.getSectionItems && getters.getSectionItems.length - 1;
    },
    getSectionItems: (state, getters) => {
      const sortByDefaultAndPrice = (a, b) => {
        if (a.is_default === b.is_default) {
          // compare by price may need to consider special price?
          return a.price - b.price;
        }
        // If 'is_default' values are different, prioritize the default item
        return a.is_default ? -1 : 1;
      };
      const hiddenSections = ['Stem', 'Cockpit'];
      const sections = getters.getBikeOptions.map((item) => {
        const sortedOptions = item.options.sort(sortByDefaultAndPrice);
        const hidden = hiddenSections.includes(item.title) || item.included;
        return {
          label: item.title,
          content: item.title,
          included: item.included,
          option_id: item.option_id,
          options: sortedOptions,
          hidden,
          required: item.required
        };
      });

      return [...sections];
    },
    getLoading: (state) => state.loading,
    getBikeError: (state) => state.errorMessage,
    getBikeTitle: (state) =>
      state.bike ? state.bike.products.items[0].name : '',
    getBikeSku: (state) => (state.bike ? state.bike.products.items[0].sku : ''),
    getProductSku: (state) => (state.product ? state.product.sku : ''),
    getCustomColour: (state) =>
      state.bike ? state.bike.products.items[0].linked_custom_colour : '',
    getBikeLoading: (state) => state.loading,
    getBikeMainImage: (state) =>
      state.bike && state.bike.products.items[0].image
        ? state.bike.products.items[0].image.url
        : '',
    getBikeParentOptions: (state) => {
      if (!state.bike) return [];
      return state.bike.products.items[0].items;
    },
    getBikeChildOptions: (state) => {
      if (!state.bike) return null;
      return state.bike.products.items[0].items.flatMap((item) => item.options);
    },
    getBikeVariantOptions: (state) => {
      if (!state.bike) return null;
      const childOptions = state.bike.products.items[0].items.flatMap(
        (item) => item.options
      );
      const products = childOptions.map((option) => option.product);
      const productsWithConfigurable = products.filter(
        (product) =>
          product.configurable_options &&
          product.configurable_options.length > 0
      );
      return productsWithConfigurable.flatMap((product) => product.variants);
    },
    getBikeProduct: (state) =>
      state.bike && state.bike.products ? state.bike.products : null,
    getDeliveryInfo: (state) => state.deliveryInfo,
    getCode: (state) => state.code
  }
};
