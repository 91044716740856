<template>
  <div class="custom-option">
    <div class="between-md">
      <div class="custom-option link-container">
        <h4> {{ option.title }} </h4>
        <div class="row middle-xs h4 link-details">
          <div class="relative price-details" v-for="opval in option.product_links" :key="opval.id">
            <input
              type="radio"
              class="m0 no-outline"
              :name="bundleOptionName + opval.id"
              :id="bundleOptionName + opval.id"
              focus
              :value="opval.id"
              v-model="productOptionId"
            >
            <label v-if="opval.product" class="m0 pointer" :for="bundleOptionName + opval.id" v-html="opval.product.name" />
          </div>
        </div>
        <div class="link-qty cl-bg-tertiary">
          <label class="text m0" :for="quantityName">{{ $t('Qty') }}</label>
          <input
            type="number"
            min="0"
            :disabled="!canChangeQty"
            class="product-qty"
            :name="quantityName"
            :id="quantityName"
            focus
            v-model="quantity"
          >
        </div>
      </div>
    </div>
    <span class="error" v-if="errorMessage">{{ errorMessage }}</span>
  </div>
</template>

<script>
import { ProductBundleOption } from '@vue-storefront/core/modules/catalog/components/ProductBundleOption.ts'

export default {
  mixins: [ProductBundleOption],
  data () {
    return {
      canChangeQty: {
        type: Boolean,
        default: false
      }
    }
  },
  computed: {
    defaultLink () {
      return Array.isArray(this.option.product_links) ? this.option.product_links.find(product => product.is_default) : false
    }
  },
  mounted () {
    this.canChangeQty = this.defaultLink ? this.defaultLink.can_change_quantity : false;
    this.$bus.$on('option-changed', ({value}) => { this.canChangeQty = value ? value.can_change_quantity : false })
  }
}
</script>

<style lang="scss" scoped>
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';
  $color-tertiary: color(tertiary);
  $color-black: color(black);
  $color-hover: color(tertiary, $colors-background);

  $bg-secondary: color(secondary, $colors-background);
  $color-secondary: color(secondary);
  $color-error: color(error);
  .product-qty {
    -moz-appearance: textfield;
  }

  .custom-option > label {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .error {
    color: $color-error;
    padding-top: 5px;
    display: block;
  }
  $color-silver: color(silver);
  $color-active: color(secondary);
  $color-white: color(white);

  .relative label.qty {
    padding-left: 5px;
  }

  input[type='text'] {
    transition: 0.3s all;
    &::-webkit-input-placeholder {
      color: $color-tertiary;
    }
    &::-moz-placeholder {
      color: $color-tertiary;
    }
    &:hover,
    &:focus {
      outline: none;
      border-color: $color-black;
    }
    background: inherit;
  }
  input::-webkit-outer-spin-button,
   input::-webkit-inner-spin-button {
     -webkit-appearance: none;
     margin: 0;
   }
  input[type='radio'], input[type='checkbox']  {
    position: absolute;
    top: 3px;
    left: 0;
    opacity: 0;
    &:hover,
    &:focus {
      + label {
        &:before {
          border-color: $color-active;
        }
      }
    }
    &:disabled + label {
      cursor: not-allowed;
      &:hover,
      &:focus {
        &:before {
          border-color: $color-silver;
          cursor: not-allowed;
        }
      }
    }
  }
  .qty-label {
    font-size: 12px !important;
    padding-left: 0 !important;
  }
</style>
