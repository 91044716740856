import { ActionTree } from 'vuex';
import * as types from './mutation-types';
import PreSpecState from './types/PreSpecState';
import RootState from '@vue-storefront/core/types/RootState'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';
import { Logger } from '@vue-storefront/core/lib/logger';
import rootStore from '@vue-storefront/core/store';
import { entityKeyName } from '@vue-storefront/core/lib/store/entities';
import { StorageManager } from '@vue-storefront/core/lib/storage-manager';
import { isOnline, quickSearchByQuery } from '@vue-storefront/core/lib/search';
import SearchQuery from '@vue-storefront/core/lib/search/searchQuery';
import { canCache } from '@vue-storefront/core/modules/catalog/helpers/search';
import config from 'config';
import { prepareRelatedQuery } from '@vue-storefront/core/modules/catalog/queries/related';

const actions: ActionTree<PreSpecState, RootState> = {
  clearPreSpec ({ commit }) {
    commit(types.CLEAR_PRE_SPEC);
  },

  setPreSpec ({ commit }, preSpec) {
    commit(types.SET_PRE_SPEC, preSpec);
  },

  async loadPreSpecProducts ({ commit, dispatch }, product) {
    const skus = product.related_specs ? product.related_specs.split(',') : [];
    if (!skus.length) return;
    try {
      const collectProductsQuery = prepareRelatedQuery('sku', skus);
      const productsResponse = await dispatch('product/list', {
        query: collectProductsQuery,
        prefetchGroupProducts: false,
        updateState: false,
        sort: 'final_price:desc'
      }, {root: true});
      commit(types.SET_PRE_SPEC_PRODUCTS, productsResponse.items);
    } catch (error) {
      console.error(error);
    }
  },

  async loadSiblingProducts ({ commit, dispatch }, product) {
    // coloured option bikes are the product_siblings
    let skus = product.product_siblings ? product.product_siblings.split(',') : [];
    if (!skus.some(sku => sku === product.sku)) {
      skus.push(product.sku);
    }
    try {
      const collectProductsQuery = prepareRelatedQuery('sku', skus);
      const productsResponse = await dispatch('product/list', {
        query: collectProductsQuery,
        prefetchGroupProducts: false,
        updateState: false
      }, {root: true});
      commit(types.SET_SIBLING_PRODUCTS, productsResponse.items);
    } catch (error) {
      console.error(error);
    }
  },

  async loadRelatedProducts ({ dispatch }, product) {
    const productLinks = product.product_links || [];
    const productLinkSkus = productLinks.filter(pl => pl.link_type === 'related').map(pl => pl.linked_product_sku);

    const key = productLinkSkus.length ? 'sku' : 'category_ids';
    const searchValues = productLinkSkus.length ? productLinkSkus : product.category_ids;
    try {
      const collectProductsQuery = prepareRelatedQuery(key, searchValues);
      const productsResponse = await dispatch('product/list', {
        query: collectProductsQuery,
        prefetchGroupProducts: false,
        updateState: false,
        size: 3
      }, {root: true});
      dispatch('product/related', {
        key: 'related',
        items: productsResponse.items
      }, {root: true});
    } catch (error) {
      console.error(error);
    }
  },

  async loadPreSpecs ({ dispatch }, options, skipCache = false) {
    Logger.info('Fetching PreSpec data asynchronously', 'prespec', {
      bikeParentId: options.bikeParentId
    })();
    EventBus.$emit('prespec-before-load', {
      store: rootStore,
      bikeParentId: options.bikeParentId
    });
    const preSpecSingleOptions = {
      bikeParentId: options.bikeParentId,
      preSpecId: options.preSpecId
    };
    let preSpec;
    try {
      preSpec = await dispatch('single', {
        options: preSpecSingleOptions,
        key: 'bikeParentId',
        skipCache: options.skipCache || skipCache
      });
    } catch (error) {
      // intentionally left blank
    }
    await EventBus.$emitFilter('prespec-after-load', {
      store: rootStore,
      preSpec: preSpec
    });

    return preSpec;
  },

  async single (context, { options, key = 'bikeParentId', skipCache = false }) {
    if (!options[key]) {
      throw Error(
        'Please provide the search key ' + key + ' for bike/single action!'
      );
    }
    const cacheKey = entityKeyName(key, options[key]);
    const preSpecId = options['preSpecId'] ? options['preSpecId'] : null;

    return new Promise((resolve, reject) => {
      const benchmarkTime = new Date();
      const cache = StorageManager.get('preSpec');

      const setupPreSpec = async preSpecs => {
        let preSpec = null;
        if (preSpecs) {
          const syncPromises = [];
          const preSpecsData = context.commit(types.SET_PRE_SPECS, preSpecs);

          preSpec = preSpecs.find(preSpec => {
            return preSpec.bikeId === preSpecId;
          });
          const preSpecData = context.dispatch('setPreSpec', preSpec);

          syncPromises.push(preSpecData);
          syncPromises.push(preSpecsData);
          await Promise.all(syncPromises);
        }

        return preSpec;
      };

      const syncPreSpecs = () => {
        let searchQuery = new SearchQuery();
        searchQuery = searchQuery.applyFilter({
          key: key,
          value: { in: options[key] }
        });

        return context.dispatch('list', {query: searchQuery})
          .then(result => {
            if (result && result.items && result.items.length) {
              let preSpecs = result.items;
              const returnPreSpecNoCacheHelper = preSpecs => {
                EventBus.$emitFilter('prespec-after-single', {
                  key: key,
                  options: options,
                  preSpec: preSpecs
                });
                resolve(setupPreSpec(preSpecs));
              };
              returnPreSpecNoCacheHelper(preSpecs);
            } else {
              context.commit(types.SET_PRE_SPEC, null);
              Logger.info('PreSpec query returned empty result', 'prespec', {
                'Search for': options[key]
              })();
              reject(new Error('PreSpec query returned empty result'));
            }
          });
      };

      const getPreSpecFromCache = () => {
        cache.getItem(cacheKey, (err, res) => {
          // report errors
          if (!skipCache && err) {
            Logger.error(err, 'prespec')();
          }
          if (res !== null) {
            Logger.debug(
              'PreSpec:single - result from localForage (for ' +
                cacheKey +
                '),  ms=' +
                (new Date().getTime() - benchmarkTime.getTime()),
              'prespec'
            )();

            const returnBikeFromCacheHelper = subresults => {
              const cachedPreSpec = setupPreSpec(res);
              EventBus.$emitFilter('prespec-after-single', {
                key: key,
                options: options,
                preSpec: cachedPreSpec
              });
              resolve(cachedPreSpec);
            };
            returnBikeFromCacheHelper(null);
          } else {
            syncPreSpecs();
          }
        });
      };

      if (!skipCache) {
        getPreSpecFromCache();
      } else {
        if (!isOnline()) {
          skipCache = false;
        }
        syncPreSpecs();
      }
    });
  },

  async list ({ dispatch }, {
    query,
    start = 0,
    size = 10,
    entityType = 'prespec',
    sort = '',
    cacheByKey = 'bikeId',
    excludeFields = null,
    includeFields = null
  }) {
    const searchResult = await dispatch(
      'findPreSpecs',
      {query, start, size, entityType, sort, cacheByKey, excludeFields, includeFields}
    );
    EventBus.$emit('prespec-after-list', {query, start, size, sort, entityType, result: searchResult});

    return searchResult;
  },

  async findPreSpecs (
    context,
    {
      query,
      start = 0,
      size = 1,
      entityType = 'prespec',
      sort = '',
      cacheByKey = 'bikeId',
      excludeFields = null,
      includeFields = null,
      populateRequestCacheTags = true
    }
  ) {
    const isCacheable = canCache({ includeFields, excludeFields });
    // const {excluded, included} = getOptimizedFields({excludeFields, includeFields})
    const storeCode = null;
    const response = await quickSearchByQuery(
      {query, start, size, entityType, sort, storeCode, excludeFields, includeFields}
    );

    return context.dispatch(
      'configureLoadedPreSpecs',
      {preSpecs: response, isCacheable, cacheByKey, populateRequestCacheTags}
    );
  },

  async configureLoadedPreSpecs (
    context,
    {
      preSpecs,
      isCacheable,
      cacheByKey = 'bikeId',
      populateRequestCacheTags,
      configuration
    }
  ) {
    for (let preSpec of preSpecs.items) {
      // we store each bike separately in cache to have offline access to bikes/single method
      if (isCacheable) {
        // store cache only for full loads
        await context.dispatch('storePreSpecToCache', { preSpec, cacheByKey });
      }
    }

    return preSpecs;
  },

  storePreSpecToCache (context, { preSpec, cacheByKey = 'bikeId' }) {
    const cacheKey = entityKeyName(cacheByKey, preSpec[cacheByKey]);
    const cache = StorageManager.get('preSpec');

    cache.setItem(cacheKey, preSpec, null, config.prespec.disablePersistentProductsCache)
      .catch(err => {
        Logger.error('Cannot store cache for ' + cacheKey, err)();
        if (err.name === 'QuotaExceededError' || err.name === 'NS_ERROR_DOM_QUOTA_REACHED') {
          // quota exceeded error
          cache.clear(); // clear preSpec cache if quota exceeded
        }
      });
  }
};

export default actions;
