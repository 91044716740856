export default function footerQuery(site) {
    return {
      query: `
        query ($site: [String]) {
          footerMenuEntries(site: $site) {
            ... on footerMenu_default_Entry {
              id
              title
              pageUrl
              externalUrl
              level
              parent {
                id
              }
            }
          }
        }
      `,
      variables: {
        site,
      },
    };
  }
