// @ts-nocheck
import axios from 'axios';
import config from 'config';
import { ColorOptionType } from 'theme/store/configurator/color.types';

export const defaultColorSku = 'BASE_COLOUR';

export async function fetchCmsData(query, token = null) {
  const url = config.craftcmsUrl;
  const fullUrl = token ? url + `?token=${token}` : url;
  try {
    const res = await axios.post(fullUrl, query);
    if (res.data.errors && res.data.errors.length) {
      console.error(res.data.errors[0]);
      throw new Error('Error with graphql api');
    }
    return res.data.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

function craftColorQuery(site, uri) {
  return {
    query: `
      query ($site: [String], $uri: [String]) {
        bikesEntries(site: $site, uri: $uri) {
          ... on bikes_configurator_Entry {
            bikeName
            customColourFolder
          }
        }
      }
    `,
    variables: {
      site,
      uri
    }
  };
}

const colorQuery = `
query products($sku:[String]!) {
	products(filter: { sku:  { in: $sku }  }) {
		items {
			sku
			name
			price_range {
				minimum_price {
					regular_price {
						value
					}
				}
			}
			uid
			... on BundleProduct {
				items {
					uid
					title
					required
					section
					section_slug
					options {
						uid
						id
						is_default
						hexcode
						angle
						price
						product {
							uid
							sku
							name
							stock_status
							image {
								url
							}
						}
					}
				}
			}
		}
	}
}
`;

export async function fetchData(query: string, variables = {}, rootGetters) {
  try {
    const code = rootGetters['bike/getCode'];
    const axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        'Content-Currency': rootGetters['esw/getUserCurrency'],
        'ESW-Country': rootGetters['esw/getUserCountry'],
        'gcdn-force': 1,
        ...(code ? { code: code } : {})
      }
    };
    const url = config.stellateUrl;
    const res = await axios.post(url, { query, variables }, axiosConfig);
    if (res.data.errors && res.data.errors.length) {
      console.error(res.data.errors[0]);
      throw new Error('Error with graphql api:' + url);
    }
    return res.data.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const colorStore = {
  namespaced: true,
  state: {
    color: null,
    loading: false,
    errorMessage: '',
    craftCC: null,
    customColor: false
  },
  mutations: {
    SET_COLOR(state, color) {
      state.color = color;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_ERROR_MESSAGE(state, errorMessage) {
      state.errorMessage = errorMessage;
    },
    SET_CC_DATA(state, craftCC) {
      state.craftCC = craftCC;
    },
    SET_CUSTOM_COLOR(state, status) {
      state.customColor = status;
    }
  },
  actions: {
    async fetchColorAssets({ commit }, payload) {
      commit('SET_LOADING', true);
      const site = payload.storeCode === 'de_de_de' ? 'ribbleDe' : 'default';
      const uri = payload.uri;
      const query = craftColorQuery(site, uri);
      try {
        const ccData = await fetchCmsData(query, payload.token);
        commit('SET_CC_DATA', ccData);
      } catch (error) {
        console.error(error);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async fetchColor({ commit, rootGetters }) {
      try {
        commit('SET_LOADING', true);
        const productSkus = rootGetters['bike/getCustomColour'];
        if (!productSkus || productSkus.length === 0) {
          throw new Error('No bike product found for custom colour query');
        }
        const variables = {
          sku: productSkus
        };
        const query = colorQuery;
        const res = await fetchData(query, variables, rootGetters);
        commit('SET_COLOR', res.products.items);
      } catch (error) {
        console.error(error);
        if (error instanceof Error) {
          commit('SET_ERROR_MESSAGE', error.message);
        }
      } finally {
        commit('SET_LOADING', false);
      }
    },
    setCustomColor: ({ commit }, status) => {
      commit('SET_CUSTOM_COLOR', status);
    },
    clearColor: ({ commit }) => {
      commit('SET_COLOR', null);
    }
  },
  getters: {
    getColors: (state) => state.color,
    getIsCustomColor: (state) => state.customColor,
    getColorLoading: (state) => state.loading,
    gradientOptions: (state, getters, rootState) => (optionId: number) => {
      const gradientOptionNone = {
        product: {
          name: 'None',
          sku: defaultColorSku
        },
        price: 0
      };
      const getSelectedOption = rootState.configurator.selectedOptions.find(
        (option) => option.option_id === optionId
      );
      const selectedColor = getters.getColors.find(
        (c) => c.sku === getSelectedOption.sku
      );
      const selectedColorGradientOption = selectedColor.items.find(
        (v) => v.title === ColorOptionType.GradientAngle
      );
      let selectedColorGradientOptions = [];
      if (selectedColorGradientOption) {
        selectedColorGradientOptions = selectedColorGradientOption.options;
      }
      return [...selectedColorGradientOptions, gradientOptionNone];
    },
    getCustomColorFolder: (state) => {
      if (
        !state.craftCC ||
        !state.craftCC.bikesEntries[0] ||
        !state.craftCC.bikesEntries[0].customColourFolder
      ) {
        console.log('custom colour folder not found');
        return null;
      }
      return state.craftCC.bikesEntries[0].customColourFolder;
    }
  }
};
