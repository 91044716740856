import attributes from './helpers/attributes';
import * as types from './mutation-types';

const actions = {
  clearColour({ commit }, bundleOption) {
    commit(types.CLEAR_BUNDLE_OPTION, bundleOption);
  },
  clearCustomColour({ commit }) {
    commit(types.CLEAR_BUNDLE_OPTIONS);
    commit(types.CLEAR_COCKPIT_MATCH);
    commit(types.CLEAR_GRADIENT_ANGLE);
  },
  clearGradientAngle({ commit }) {
    commit(types.CLEAR_GRADIENT_ANGLE);
  },
  clearCockpitMatch({ commit }) {
    commit(types.CLEAR_COCKPIT_MATCH);
  },
  toggleBike({ commit }) {
    commit(types.SET_SHOW_BIKE);
  },
  setBundleOption({ commit, dispatch, rootGetters, getters }, bundleOption) {
    const frameSwatch = getters.frameSwatch;
    const decalSwatch = getters.decalSwatch;

    if (!bundleOption) return;

    if (
      bundleOption.slug === 'gradient_angle' &&
      bundleOption.value &&
      bundleOption.value.angle
    ) {
      /**
      const option = rootGetters['builderOption/getOptionById'](
        bundleOption.optionId
      );
        **/
      commit(types.SET_GRADIENT_ANGLE, bundleOption.value.angle);
    }

    if (
      frameSwatch &&
      bundleOption.slug === 'decal_colour' &&
      frameSwatch.swatch === bundleOption.swatch
    ) {
      dispatch('builderUi/toggleCustomColorModal', null, { root: true });
      return;
    }

    if (
      decalSwatch &&
      bundleOption.slug === 'frame_colour' &&
      decalSwatch.swatch === bundleOption.swatch
    ) {
      dispatch('builderUi/toggleCustomColorModal', null, { root: true });
      return;
    }

    if (bundleOption.slug === attributes.COLOUR_MATCH_COCKPIT) {
      commit(types.SET_COCKPIT_MATCH, true);
    }
    commit(types.SET_BUNDLE_OPTION, bundleOption);
  },
  setDefaultBundleOptions({ dispatch, getters }) {
    const defaultOptions = getters.defaultCCBundleOptions;
    defaultOptions.forEach((bundleOption) => {
      dispatch('setBundleOption', bundleOption);
    });
  },
  setMyBuildBundleOptions({ dispatch }, ccOptions) {
    ccOptions.forEach((bundleOption) => {
      dispatch('setBundleOption', bundleOption);
    });
  }
};

export default actions;
