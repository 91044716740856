import axios from 'axios';
import config from 'config';
import Cookies from 'js-cookie';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';

type CookieValue = { country: { iso_code: string } };

function setLocalisation(country, currency, commit, dispatch) {
  commit('SET_USER_COUNTRY', country);
  commit('SET_USER_CURRENCY', currency);
  dispatch('ayko_localisation/setDefaultCountry', country, { root: true });
  dispatch('ayko_localisation/setCountry', country, { root: true });
  dispatch('ayko_localisation/setDefaultCurrency', currency, { root: true });
  dispatch('ayko_localisation/setCurrency', currency, { root: true });
}

function setEwsCountryCookie(country) {
  const cookieValue: CookieValue = {
    country: { iso_code: country }
  };

  const value = JSON.stringify(cookieValue);

  const storeCode = currentStoreView().storeCode;
  const domain = storeCode === 'de_de_de' ? config.esw.cookieDomainDe : config.esw.cookieDomain;

  Cookies.set('esw-location', value, {
    expires: Number(config.ayko.localisation.cookie.expires),
    domain
  });
}

function setEwsCurrencyCookie(currency) {
  const storeCode = currentStoreView().storeCode;
  const domain = storeCode === 'de_de_de' ? config.esw.cookieDomainDe : config.esw.cookieDomain;

  Cookies.set('esw-currency', currency, {
    expires: Number(config.ayko.localisation.cookie.expires),
    domain
  });
}

export const eswStore = {
  namespaced: true,
  state: {
    userCountry: 'GB',
    userCurrency: 'GBP'
  },
  mutations: {
    SET_USER_COUNTRY(state, countryCode) {
      state.userCountry = countryCode;
    },
    SET_USER_CURRENCY(state, userCurrency) {
      state.userCurrency = userCurrency;
    }
  },
  actions: {
    async updateUserLocation({ commit, dispatch }, query = null) {
      let country = query && query.country ? query.country : null;
      let currency = null;
      if (country) {
        currency = config.ayko.localisation.availableCountries.find((item) => item.code === country).currency;
        setLocalisation(country, currency, commit, dispatch);
        setEwsCountryCookie(country);
        setEwsCurrencyCookie(currency);
        return;
      };
      country = Cookies.get('esw-location');
      currency = Cookies.get('esw-currency');
      if (country && currency) {
        const parsedCountry = JSON.parse(country);
        const countryCode = parsedCountry.country.iso_code;
        setLocalisation(countryCode, currency, commit, dispatch);
        return;
      };
      try {
        const countryLookup = await axios.get(config.cloudflareUrl);
        let configData = config.ayko.localisation.availableCountries.find((item) => item.code === countryLookup.data.country);
        if (!configData) {
          configData = config.ayko.localisation.availableCountries.find((item) => item.code === 'GB');
        };
        setLocalisation(configData.code, configData.currency, commit, dispatch);
      } catch (error) {
        console.error(error);
      }
    }
  },
  getters: {
    getUserCountry: state => {
      return state.userCountry;
    },
    getUserCurrency: state => {
      return state.userCurrency;
    },
    getUserLocale: state => {
      const availableCurrencies = config.ayko.localisation.availableCurrencies;
      const locale = availableCurrencies.find((currency) => currency.name === state.userCurrency);
      return locale ? locale.defaultLocale : 'en-GB';
    },
    useEsw: (state) => {
      if (config.esw.enabled === true && config.esw.nonEswCountries.includes(state.userCountry) === false) return true;
      return false;
    }
  }
};
