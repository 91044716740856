export default function blogPostsTagListingQuery(variables) {
	return {
		query: `
    query ($site: [String], $offset: Int, $limit: Int, $tags: [TagCriteriaInput], $tagSlug: [String]) {
      entryCount(section: "blog", relatedToTags: $tags)
      blogEntries(site: $site, offset: $offset, limit: $limit, relatedToTags: $tags) {
        ... on blog_default_Entry {
          id
          postDate
          slug
          title
          summary
          typeHandle
          blogFeaturedImage {
            ... on blog_Asset {
              id
              url(transform: "img625x415")
            }
          }
        }
        ... on blog_legacy_Entry {
          id
          postDate
          slug
          title
          summary
          typeHandle
          blogFeaturedImage {
            ... on blog_Asset {
              id
              url(transform: "img625x415")
            }
          }
        }
      }
      tags(slug: $tagSlug) {
        ... on blog_Tag {
          id
          metaDescription
          metaKeywords
          metaTitle
          title
        }
      }
    }
  `,
		variables,
	};
}
