export default function homeHeroQuery(site) {
  return {
    query: `
        query ($site: [String]) {
          entries(site: $site) {
            ... on homeHero_default_Entry {
              id
              title
              status
              heroHeadingOne
              heroHeadingTwo
              headingStyles
              heroCtaUrl
              heroCtaText
              heroImage {
                id
                url
              }
              mobileImage {
                id
                url(transform: "img640x640")
              }
              heroVideo {
                id
                url
              }
              mobileVideo {
                id
                url
              }
            }
          }
        }
      `,
    variables: {
      site
    }
  };
}
