export default function anniversaryQuery (site) {
  return {
    query: `
    query ($site: [String]) {
      anniversary125Entries(site: $site) {
        ... on anniversary125_anniversary125_Entry {
          title
          darkMode
          metaTitle
          metaDescription
          metaKeywords
          robots
          heroImage {
            ... on heros_Asset {
              id
              url
            }
          }
          heroVideo {
            ... on heros_Asset {
              id
              url
            }
          }
          pageBuilder {
            ... on pageBuilder_hero_BlockType {
              id
              height
              typeHandle
              headingOne
              headingTwo
              ctaText
              ctaUrl
              image {
                ... on heros_Asset {
                  id
                  url
                }
              }
              video {
                ... on heros_Asset {
                  id
                  url
                }
              }
            }
            ... on pageBuilder_headingBlock_BlockType {
              id
              width
              style
              typeHandle
              heading
            }
            ... on pageBuilder_textBlock_BlockType {
              id
              width
              style
              typeHandle
              text
            }
            ... on pageBuilder_imageGrid_BlockType {
              id
              width
              typeHandle
              imagesLeft {
                ... on misc_Asset {
                  id
                  url
                }
              }
              imagesRight {
                ... on misc_Asset {
                  id
                  url
                }
              }
            }
            ... on pageBuilder_carousel_BlockType {
              id
              width
              height
              typeHandle
              images {
                ... on misc_Asset {
                  id
                  url
                }
              }
            }
            ... on pageBuilder_textOnImage_BlockType {
              id
              width
              typeHandle
              heading
              text
              textPosition
              image {
                ... on misc_Asset {
                  id
                  url
                }
              }
            }
            ... on pageBuilder_quote_BlockType {
              id
              typeHandle
              text
              quotedBy
            }
            ... on pageBuilder_image_BlockType {
              id
              width
              typeHandle
              image {
                ... on misc_Asset {
                  id
                  url
                }
              }
            }
            ... on pageBuilder_video_BlockType {
              id
              typeHandle
              video {
                ... on misc_Asset {
                  id
                  url
                }
              }
            }
          }
        }
      }
    }
    `,
    variables: {
      site
    }
  };
}
