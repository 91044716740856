import { isServer } from '@vue-storefront/core/helpers';

export default {
  data () {
    return {
      isMobile: this.getIsMobile()
    }
  },

  created () {
    if (!isServer) {
      window.addEventListener('resize', () => {
        this.isMobile = this.getIsMobile()
      })
    }
  },

  destroyed () {
    if (!isServer) {
      window.removeEventListener('resize', () => {
        this.isMobile = this.getIsMobile()
      })
    }
  },

  methods: {
    getIsMobile (): boolean {
      return isServer ? false : screen.availWidth < 767
    }
  }
}
