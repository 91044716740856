import axios from 'axios';
import config from 'config';
// @ts-ignore
import { marked } from 'marked';
import promoQuery from './craft-queries/promotions';
import mainMenuQuery from './craft-queries/main-menu';
import homeHeroQuery from './craft-queries/home-hero';
import blogPostQuery from './craft-queries/blog-post';
import socialQuery from './craft-queries/social-links';
import footerQuery from './craft-queries/footer-links';
import pageBuilderQuery from './craft-queries/page-builder';
import anniversaryQuery from './craft-queries/125-anniversary';
import categoryMenuQuery from './craft-queries/category-menu-query';
import blogPostsListingQuery from './craft-queries/blog-posts-listing-query';
import blogPostsTagListingQuery from './craft-queries/blog-posts-tag-listing-query';
import blogPostsCategoryListingQuery from './craft-queries/blog-posts-category-listing-query';

export async function fetchCmsData(query, token = null) {
  const url = config.craftcmsUrl;
  const fullUrl = token ? url + `?token=${token}` : url;
  try {
    const res = await axios.post(fullUrl, query);
    if (res.data.errors && res.data.errors.length) {
      console.error(res.data.errors[0]);
      throw new Error('Error with graphql api');
    }
    return res.data.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

function parseMarkdown(data) {
  if (!data) return [];
  return data.pageBuilder.map((block) => {
    let newBlock = block;
    if (block.typeHandle === 'textBlock') {
      newBlock.text = marked.parse(block.text);
    }
    if (block.typeHandle === 'textByImage') {
      newBlock.text = marked.parse(block.text);
    }
    if (block.typeHandle === 'textOnImage') {
      newBlock.text = marked.parse(block.text);
    }
    if (block.typeHandle === 'emailSignUp') {
      newBlock.text = marked.parse(block.text);
    }
    if (block.typeHandle === 'imageTextGrid') {
      if (!block.grid) return null;
      newBlock.grid = block.grid.map((item) => {
        const newItem = item;
        newItem.text = marked.parse(item.text);
        return newItem;
      });
    }
    if (block.typeHandle === 'faqMenu') {
      if (!block.faqs) return null;
      newBlock.faqs = block.faqs.map((item) => {
        const newItem = item;
        newItem.body = marked.parse(item.body);
        return newItem;
      });
    }
    return newBlock;
  });
}

export const craftCmsStore = {
  namespaced: true,
  state: {
    homeHero: null,
    anniversaryData: null,
    pageData: null,
    footerData: [],
    socialData: [],
    activeFooterMenu: null,
    activeMenu: null,
    showSearch: false,
    menuData: [],
    showMobileMenu: false,
    blogPost: null,
    blogPostsListing: null,
    blogPostsTagListing: null,
    blogPostsCategoryListing: null,
    categoryMenuData: null,
    siteMessageData: [],
    promoData: []
  },
  mutations: {
    SET_HOME_HERO(state, content) {
      state.homeHero = content;
    },
    SET_ANNIVERSARY_PAGE(state, content) {
      state.anniversaryData = content;
    },
    SET_PAGE_DATA(state, content) {
      state.pageData = content;
    },
    SET_FOOTER_DATA(state, content) {
      state.footerData = content;
    },
    SET_SOCIAL_DATA(state, content) {
      state.socialData = content;
    },
    SET_FOOTER_MENU_STATE(state, menuId) {
      state.activeFooterMenu = menuId;
    },
    SET_MENU_STATE(state, menuId) {
      state.activeMenu = menuId;
    },
    SET_MENU_DATA(state, content) {
      state.menuData = content;
    },
    SET_SHOW_SEARCH(state, payload) {
      if (payload === false) state.showSearch = false;
      state.showSearch = !state.showSearch;
    },
    SET_SHOW_SEARCH_CLOSED(state) {
      state.showSearch = false;
    },
    SET_MOBILE_MENU_CLOSED(state) {
      state.showMobileMenu = false;
      document.body.classList.remove('menu-open');
    },
    SET_SHOW_MOBILE_MENU(state) {
      state.showMobileMenu = !state.showMobileMenu;
      if (state.showMobileMenu) {
        document.body.classList.add('menu-open');
      } else {
        document.body.classList.remove('menu-open');
      }
    },
    SET_BLOG_POST(state, content) {
      state.blogPost = content;
    },
    SET_BLOG_POSTS_LISTING(state, content) {
      state.blogPostsListing = content;
    },
    SET_BLOG_POSTS_TAG_LISTING(state, content) {
      state.blogPostsTagListing = content;
    },
    SET_BLOG_POSTS_CATEGORY_LISTING(state, content) {
      state.blogPostsCategoryListing = content;
    },
    SET_CATEGORY_MENU(state, content) {
      state.categoryMenuData = content;
    },
    SET_SITE_MESSAGE_DATA(state, content) {
      state.siteMessageData = content;
    },
    SET_PROMO_DATA(state, content) {
      state.promoData = content;
    }
  },
  actions: {
    async updateAnniversaryPage({ commit }, payload) {
      const site = payload.storeCode === 'de_de_de' ? 'ribbleDe' : 'default';
      const query = anniversaryQuery({ site });
      try {
        const content = await fetchCmsData(query, payload.token);
        commit('SET_ANNIVERSARY_PAGE', content);
      } catch (error) {
        console.error(error);
      }
    },
    async updatePageData({ commit }, payload) {
      const site = payload.storeCode === 'de_de_de' ? 'ribbleDe' : 'default';
      const uri = payload.uri;
      const query = pageBuilderQuery(site, uri);
      try {
        const content = await fetchCmsData(query, payload.token);
        commit('SET_PAGE_DATA', content);
      } catch (error) {
        console.error(error);
      }
    },
    async updateHomeHero({ commit }, payload) {
      const site = payload.storeCode === 'de_de_de' ? 'ribbleDe' : 'default';
      const query = homeHeroQuery({ site });
      try {
        const content = await fetchCmsData(query);
        commit('SET_HOME_HERO', content);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchMenuData({ commit, getters, dispatch }, payload) {
      const site = payload.storeCode === 'de_de_de' ? 'ribbleDe' : 'default';
      const query = mainMenuQuery(site);
      try {
        const content = await fetchCmsData(query);
        const menuData = content ? content.mainMenuEntries : [];
        const messageData = content ? content.siteMessagesEntries : [];
        commit('SET_MENU_DATA', menuData);
        commit('SET_SITE_MESSAGE_DATA', messageData);
        const hasTrending = getters.levelOneData.filter(
          (x) => x.trending && x.trending.length
        );
        const skus = hasTrending.map((x) => x.trending[0].sku);
        await dispatch('trending/fetchPricesFromSkus', skus, { root: true });
      } catch (error) {
        console.error(error);
      }
    },
    async fetchFooterData({ commit }, payload) {
      const site = payload.storeCode === 'de_de_de' ? 'ribbleDe' : 'default';
      const query = footerQuery({ site });
      try {
        const content = await fetchCmsData(query);
        const footerLinks = content ? content.footerMenuEntries : [];
        commit('SET_FOOTER_DATA', footerLinks);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchSocialData({ commit }, payload) {
      const site = payload.storeCode === 'de_de_de' ? 'ribbleDe' : 'default';
      const query = socialQuery({ site });
      try {
        const content = await fetchCmsData(query);
        const socialLinks = content ? content.globalSets[0].menuLinks : [];
        commit('SET_SOCIAL_DATA', socialLinks);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchPromoData({ commit }, payload) {
      const site = payload.storeCode === 'de_de_de' ? 'ribbleDe' : 'default';
      const query = promoQuery({ site });
      try {
        const content = await fetchCmsData(query);
        const promoData = content ? content : [];
        commit('SET_PROMO_DATA', promoData);
      } catch (error) {
        console.error(error);
      }
    },
    openFooterMenu({ commit }, menuId) {
      commit('SET_FOOTER_MENU_STATE', menuId);
    },
    closeFooterMenu({ commit }) {
      commit('SET_FOOTER_MENU_STATE', null);
    },
    openMenu({ commit }, menuId) {
      commit('SET_MENU_STATE', menuId);
    },
    closeMenu({ commit }) {
      commit('SET_MENU_STATE', null);
    },
    toggleSearch({ commit }) {
      commit('SET_SHOW_SEARCH');
    },
    closeSearch({ commit }) {
      commit('SET_SHOW_SEARCH_CLOSED');
    },
    toggleMobileMenu({ commit }) {
      commit('SET_SHOW_MOBILE_MENU');
    },
    closeMobileMenu({ commit }) {
      commit('SET_MOBILE_MENU_CLOSED');
    },
    async updateBlogPost({ commit }, payload) {
      const site = payload.storeCode === 'de_de_de' ? 'ribbleDe' : 'default';
      const slug = payload.slug;
      const query = blogPostQuery(site, slug);
      try {
        const content = await fetchCmsData(query, payload.token);
        commit('SET_BLOG_POST', content);
      } catch (error) {
        console.error(error);
      }
    },
    async updateBlogPostListing({ commit }, payload) {
      const site = payload.storeCode === 'de_de_de' ? 'ribbleDe' : 'default';
      const variables = {
        site: site,
        offset: payload.offset,
        limit: payload.limit
      };
      const query = blogPostsListingQuery(variables);
      try {
        const content = await fetchCmsData(query, payload.token);
        commit('SET_BLOG_POSTS_LISTING', content);
      } catch (error) {
        console.error(error);
      }
    },
    async updateBlogPostTagListing({ commit }, payload) {
      const site = payload.storeCode === 'de_de_de' ? 'ribbleDe' : 'default';
      const variables = {
        site: site,
        offset: payload.offset,
        limit: payload.limit,
        tags: payload.tags,
        tagSlug: payload.tagSlug
      };
      const query = blogPostsTagListingQuery(variables);
      try {
        const content = await fetchCmsData(query, payload.token);
        commit('SET_BLOG_POSTS_TAG_LISTING', content);
      } catch (error) {
        console.error(error);
      }
    },
    async updateBlogPostCategoryListing({ commit }, payload) {
      const site = payload.storeCode === 'de_de_de' ? 'ribbleDe' : 'default';
      const variables = {
        site: site,
        offset: payload.offset,
        limit: payload.limit,
        category: payload.category,
        categorySlug: payload.categorySlug
      };
      const query = blogPostsCategoryListingQuery(variables);
      try {
        const content = await fetchCmsData(query, payload.token);
        commit('SET_BLOG_POSTS_CATEGORY_LISTING', content);
      } catch (error) {
        console.error(error);
      }
    },
    async updateCategoryMenu({ commit }, payload) {
      const slug = payload.slug;
      const query = categoryMenuQuery({ slug });
      try {
        const content = await fetchCmsData(query);
        commit('SET_CATEGORY_MENU', content);
      } catch (error) {
        console.error(error);
      }
    }
  },
  getters: {
    homeHero: (state) => state.homeHero,
    showSearch: (state) => state.showSearch,
    trendingPrices: (state) => state.trendingPrices,
    showMobileMenu: (state) => state.showMobileMenu,
    anniversaryData: (state) => state.anniversaryData,
    pageData: (state) => {
      if (!state.pageData || !state.pageData.pageBuilderEntries) return null;
      const data = state.pageData.pageBuilderEntries[0];
      if (!data) return null;
      data.pageBuilder = parseMarkdown(data);
      return data;
    },
    ccPromoData: (state) => {
      if (!state.promoData || !state.promoData.entries) return null;
      const ccData = state.promoData.entries.filter(
        (x) => x.slug === 'custom-colour' && x.sectionHandle === 'promotions'
      );
      ccData.map((x) => {
        x.shortFormMarkdown = marked.parse(x.shortFormText);
        x.longFormMarkdown = marked.parse(x.longFormText);
      });
      return ccData[0];
    },
    getActiveMenuState: (state) => state.activeMenu,
    getActiveFooterState: (state) => state.activeFooter,
    menuData: (state) => {
      const dataCheck = state.menuData.filter((x) => {
        return (
          (x.level === 1 && x.typeHandle === 'levelOne') ||
          (x.level === 2 && x.typeHandle === 'levelTwo') ||
          (x.level === 3 && x.typeHandle === 'levelThree')
        );
      });
      return dataCheck.sort((a, b) => a.lft > b.lft);
    },
    levelOneData: (state, getters) => {
      if (!getters.menuData) return [];
      return getters.menuData.filter((x) => x.level === 1);
    },
    levelTwoByParent: (state, getters) => (parentId) => {
      if (!getters.menuData) return [];
      return getters.menuData.filter(
        (x) => x.parent && x.parent.id === parentId
      );
    },
    activeLevelOneData: (state, getters) => {
      if (!state.activeMenu || !getters.menuData) return [];
      return getters.menuData.find((x) => x.id === state.activeMenu);
    },
    activeLevelTwoData: (state, getters) => {
      if (!state.activeMenu || !getters.menuData) return [];
      return getters.menuData.filter(
        (x) => x.parent && x.parent.id === state.activeMenu
      );
    },
    activeTrending: (state, getters) => {
      if (!getters.activeLevelOneData) return null;
      const trending = getters.activeLevelOneData.trending;
      if (trending && trending.length) return trending[0];
      return null;
    },
    activeFooterMenu: (state) => state.activeFooterMenu,
    footerMenu: (state) => state.footerData,
    footerSocialLinks: (state) => state.socialData,
    levelOneFooterMenu: (state) =>
      state.footerData.filter((x) => x.level === 1),
    levelTwoFooterMenu: (state) => (levelOneId) =>
      state.footerData.filter(
        (x) => x.level === 2 && x.parent.id === levelOneId
      ),
    levelThreeMainMenu: (state) => (levelTwoId) =>
      state.menuData.filter(
        (x) => x.level === 3 && x.parent && x.parent.id === levelTwoId
      ),
    getBlogPost: (state) => {
      if (!state.blogPost) return null;
      if (state.blogPost.blogEntries[0].typeHandle === 'legacy')
        return state.blogPost.blogEntries[0];
      const data = state.blogPost.blogEntries[0];
      data.pageBuilder = parseMarkdown(data);
      return data;
    },
    getBlogPostBodyHTML: (state) => {
      if (state.blogPost.blogEntries[0].typeHandle === 'default') return null;
      return marked.parse(state.blogPost.blogEntries[0].body);
    },
    getBlogPostsListing: (state) => state.blogPostsListing,
    getBlogPostsTagListing: (state) => state.blogPostsTagListing,
    getBlogPostsCategoryListing: (state) => state.blogPostsCategoryListing,
    getTotalBlogPosts: (state) =>
      state.blogPostsListing ? state.blogPostsListing.entryCount : 0,
    getTotalBlogPostsTag: (state) =>
      state.blogPostTagListing ? state.blogPostsTagListing.entryCount : 0,
    getTotalBlogPostsCategory: (state) =>
      state.blogPostsCategoryListing
        ? state.blogPostsCategoryListing.entryCount
        : 0,
    getCategoryMenuData: (state) => state.categoryMenuData,
    siteMessageData: (state) =>
      state.siteMessageData
        .sort((a, b) => b.lft - a.lft)
        .map((x) => {
          return {
            id: x.id,
            message: marked.parse(x.message)
          };
        })
  }
};
