import { GetterTree } from 'vuex';
import RootState from 'core/types/RootState';
import attributes from './helpers/attributes';
import CustomColorState from './types/CustomColorState';
import CustomColorBundle from './types/CustomColorBundle';

function getCustomColorBundle (option, ccParentOption) {
  const bundleOptions = ccParentOption.option.bundleOptions;
  if (!bundleOptions) return null;
  return bundleOptions.find(bundleOption => bundleOption.option_id === option.bundleOption.option_id);
}

function setSwatch (option, getters, rootGetters) {
  const ccSectionId = rootGetters['builderSection/ccSectionId'];
  const selectedParentOptions = rootGetters['build/selectedParentOptions'];
  const ccSelectedParentOptions = selectedParentOptions.filter(parentOption => parentOption.option.sectionId === ccSectionId);
  const ccParentOption = ccSelectedParentOptions.find(parentOption => parentOption.option.sectionId === ccSectionId);
  const customColorBundle = ccParentOption ? getCustomColorBundle(option, ccParentOption) : null;
  const attributes = getters.getCCAttributes(option);
  return {
    bundleOptionId: option.bundleOption.option_id,
    optionId: option.id,
    swatch: attributes && attributes.swatch ? attributes.swatch : null,
    price: option.priceChange.price_incl_tax,
    name: option.product.name,
    position: option.position,
    slug: customColorBundle ? customColorBundle.slug : null,
    images: option.product.images
  };
}

const getters: GetterTree<CustomColorState, RootState> = {
  showBike: state => {
    return state.showBike;
  },
  selectedCustomColorOptions: state => {
    return state.bundleOptions;
  },
  selectedCustomColorOptionIds: state => {
    return state.bundleOptions ? state.bundleOptions.map(option => option.optionId) : [];
  },
  frameSwatch: state => {
    return state.bundleOptions
      ? state.bundleOptions.find(option => option.slug === 'frame_colour')
      : null;
  },
  decalSwatch: state => {
    return state.bundleOptions
      ? state.bundleOptions.find(option => option.slug === 'decal_colour')
      : null;
  },
  gradientColor: state => {
    return state.bundleOptions
      ? state.bundleOptions.find(option => option.slug === 'gradient_colour')
      : null;
  },
  gradientAngle: state => {
    return state.gradientAngle;
  },
  cockpitMatch: state => {
    return state.cockpitMatch;
  },
  paintFinish: (state, getters, rootState, rootGetters) => {
    const paintFinish = getters.selectedCustomColorOptions
      ? getters.selectedCustomColorOptions.find(
        (option: CustomColorBundle) => option.slug === attributes.PAINT_FINISH
      )
      : null;
    if (paintFinish) {
      const nameMap = {
        "Paint Finish Matt":"Matt",
        "Paint Finish Gloss":"Gloss"
      }
      return nameMap[paintFinish.name];
      /**
      const option = rootGetters['builderOption/getOptionById'](
        paintFinish.optionId
      );
      if (!option) return null;
      return option.product.attributes.find(
        attr => attr.attributeCode === attributes.BIKE_PAINT_FINISH
      ).attributeValue;
        **/

    }
    return null;
  },
  highlightOpacity: (state, getters) => {
    if (getters.paintFinish === attributes.GLOSS) {
      // Gloss paint
      return '0.5';
    }
    if (getters.paintFinish === attributes.METALLIC) {
      // Metallic paint
      return '0.7';
    }
    if (getters.paintFinish === attributes.MATT) {
      // Matt paint
      return '0.3';
    }
    if (getters.paintFinish === attributes.GLITTER) {
      // Glitter paint
      return '0.9';
    }
    return null;
  },
  metallicOpacity: (state, getters) => {
    if (
      getters.paintFinish === attributes.MATT ||
      getters.paintFinish === attributes.GLOSS
    ) {
      // Matt or Gloss paint
      return '0';
    }
    if (getters.paintFinish === attributes.METALLIC) {
      // Metallic paint
      return '0.5';
    }
    if (getters.paintFinish === attributes.GLITTER) {
      // Glitter paint
      return '1';
    }
    return null;
  },
  allCustomColorSwatches: (state, getters, rootState, rootGetters) => {
    const ccSectionId: number = rootGetters['builderSection/ccSectionId'];
    const ccOptions = rootGetters['builderOption/getOptionsBySectionId'](ccSectionId);
    const ccBundleOptions = ccOptions.filter((option) => option.bundleOption);
    const swatches = [];
    ccBundleOptions.forEach(option => {
      const ccAttributes = getters.getCCAttributes(option);
      if (ccAttributes) {
        swatches.push(setSwatch(option, getters, rootGetters));
      }
    });
    return swatches;
  },
  gradientColors: (state, getters) => getters.allCustomColorSwatches.filter(x => x.slug === 'gradient_colour'),
  gradientAngles: (state, getters) => getters.allCustomColorSwatches.filter(x => x.slug === 'gradient_angle'),
  customColorParentOption: (state, getters, rootState, rootGetters) => {
    const ccSectionId: number = rootGetters['builderSection/ccSectionId'];
    const buildCCOptions = rootGetters['build/getOptionsBySectionId'](ccSectionId);
    return buildCCOptions.find(ccOption => ccOption.option.parentOptionId === null);
  },
  customColorBundles: (state, getters) => {
    if (!getters.customColorParentOption) {
      return [];
    }
    const bundleOptions = getters.customColorParentOption.option.bundleOptions;
    return bundleOptions ? bundleOptions.sort((a, b) => a.position - b.position) : [];
  },
  getCCAttributes: () => (option) => {
    if (!option.product.attributes || !option.product.attributes.length) {
      return null;
    }
    return option.product.attributes.reduce((obj, item) => {
      obj[item.key] = item.value;
      return obj;
    });
  },
  defaultCCSwatches: (state, getters, rootState, rootGetters) => {
    const ccSectionId: number = rootGetters['builderSection/ccSectionId'];
    const ccOptions = rootGetters['builderOption/getOptionsBySectionId'](ccSectionId);
    const ccBundleOptions = ccOptions.filter(option => option.bundleOption);
    return ccBundleOptions.length
      ? ccBundleOptions.filter(option => option.isDefault)
      : [];
  },
  defaultCCBundleOptions: (state, getters, rootState, rootGetters) => {
    const swatches = [];
    getters.defaultCCSwatches.forEach(option => {
      const attributes = getters.getCCAttributes(option);
      if (attributes) {
        swatches.push(setSwatch(option, getters, rootGetters));
      }
    });
    return swatches;
  },
  myBuildCCBundleOptions: (state, getters, rootState, rootGetters) => (ccOptions) => {
    const ccBundleOptions = ccOptions.filter(option => option.bundleOption);
    const swatches = [];
    ccBundleOptions.forEach(option => {
      const attributes = getters.getCCAttributes(option);
      if (attributes) {
        swatches.push(setSwatch(option, getters, rootGetters));
      }
    });
    return swatches;
  }
};

export default getters;
