<template>
  <div>
    <div v-if="product.special_price_incl_tax !== 0" :class="textSize" class="font-bold leading-none font-featured mb-2" >
      {{ localisePrice(product.special_price_incl_tax, storeView) }}
    </div>
    <div v-else :class="textSize" class="font-bold leading-none font-featured mb-2">
      <span v-if="fromPrice" class="mr-1">{{ $t('From') }}:</span>
      <span>{{ localisePrice(product.final_price_incl_tax, storeView) }}</span>
    </div>
    <template v-if="product.special_price_incl_tax !== 0">
      <div class="leading-none mb-1">
        Was {{ localisePrice(product.original_price_incl_tax, storeView) }}
      </div>
      <div class="font-bold leading-none font-featured text-red-500">
        Save {{ localisePrice(saving, storeView) }}
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';

export default {
  props: {
    product: {
      type: Object,
      required: true
    },
    fromPrice: {
      type: Boolean,
      default: false
    },
    textSize: {
      type: String,
      default: 'text-xl'
    }
  },
  computed: {
    ...mapGetters({
      localisePrice: 'ayko_localisation/localisePrice'
    }),
    storeView () {
      return currentStoreView();
    },
    salePrice () {
      return this.product.special_price_incl_tax;
    },
    saving () {
      if (this.product.special_price_incl_tax === 0) return 0;
      return this.product.original_price_incl_tax - this.product.special_price_incl_tax;
    }
  }
}
</script>