<template>
  <!-- Mobile carousel view -->
  <div class="product-listing" v-if="enableMobileCarousel && isMobile">
    <carousel
      :per-page="1"
      :controls="false"
      indicator-type="disc"
    >
      <slide
        v-for="(product) in productsCapped"
        :key="product.id"
      >
        <product-tile :product="product" />
      </slide>
    </carousel>
  </div>

  <!-- Standard grid view -->
  <div
    v-else
    class="product-listing"
    :class="`grid-columns-${columns}`"
  >
    <div
      v-for="(product, index) in productsCapped"
      :key="product.id+'-'+index"
      class="flex u-margin-bottom"
    >
      <product-tile :product="product" />
    </div>
  </div>
</template>

<script>
import ProductTile from 'theme/components/core/ProductTile'
import isMobileMixin from '../../mixins/isMobileMixin'

let co = typeof window !== 'undefined';
let lastHero = 0

export default {
  name: 'ProductListing',

  components: {
    ProductTile,
    Carousel: () => co ? import('vue-carousel').then(Slider => Slider.Carousel) : null,
    Slide: () => co ? import('vue-carousel').then(Slider => Slider.Slide) : null
  },

  mixins: [
    isMobileMixin
  ],

  props: {
    products: {
      type: null,
      required: true
    },

    columns: {
      type: [Number, String],
      default: 'adaptive'
    },

    enableMobileCarousel: {
      type: Boolean,
      default: false
    },

    // Do not limit the amount if set to 0
    maxDisplayed: {
      type: Number,
      default: 0
    }
  },

  computed: {
    productsCapped () {
      if (this.maxDisplayed) {
        return this.products.slice(0, this.maxDisplayed)
      } else {
        return this.products
      }
    }
  },

  methods: {
    // Not used at all, needs removing?
    wide (isOnSale, isNew, index) {
      let deltaCondition = index > 0 && ((index - 1) - lastHero) % 2 === 0
      // last image always shouldn't be big, we also need to count from last promoted to check if it will look ok
      let isHero = ((isOnSale === '1' || isNew === '1') && deltaCondition) || (index === this.products.length - 1 && (index - lastHero) % 2 !== 0)
      if (isHero) {
        lastHero = index
      }
      return isHero ? 'col-xs-12' : 'col-xs-6'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/grid';

@for $i from 1 through 8 {
  .grid-columns-#{$i} {
    grid-template-columns: repeat($i, 1fr);
  }
}

.grid-columns-adaptive {
  @media #{$media-sm-x} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media #{$media-md-up} {
    grid-template-columns: repeat(4, 1fr);
  }
}

</style>
