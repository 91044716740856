export default function socialQuery(site) {
  return {
    query: `
      query ($site: [String]) {
        globalSets(site: $site) {
          ... on socialLinks_GlobalSet {
            id
            name
            menuLinks {
              ... on menuLinks_linkBlock_BlockType {
                id
                linkUrl
                linkText
                linkIcon
              }
            }
          }
        }
      }
    `,
    variables: {
      site
    }
  };
}
