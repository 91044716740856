import { GetterTree } from 'vuex';
import PreSpecState from './types/PreSpecState';
import PreSpecSection from './types/PreSpecSection';
import RootState from '@vue-storefront/core/types/RootState'

const getters: GetterTree<PreSpecState, RootState> = {
  preSpec: (state) => {
    return state.preSpec;
  },
  preSpecName: (state) => {
    return state.preSpec ? state.preSpec.preSpecName : null;
  },
  preSpecs: (state) => {
    return state.preSpecs.filter(preSpec => preSpec.bikeId !== preSpec.bikeParentId);
  },
  preSpecParent: (state) => {
    return state.preSpecs.find(preSpec => preSpec.bikeId === preSpec.bikeParentId) || null;
  },
  preSpecProducts: (state) => {
    return state.preSpecProducts;
  },
  siblingProducts: (state) => {
    return state.siblingProducts;
  },
  preSpecPriceOptions: (state) => {
    let chosenPreSpec;
    if (!state.preSpec || !state.preSpec.sections) {
      // If prespec not set it's custom build,
      // if custom build check it has defaults set in case the are included in price.
      state.preSpecs.forEach((preSpecChild) => {
        if (preSpecChild.bikeId === preSpecChild.bikeParentId) {
          chosenPreSpec = preSpecChild;
        }
      });
    }
    else {
      chosenPreSpec=state.preSpec;
    }

    if (!chosenPreSpec || !chosenPreSpec.sections) {
      return [];
    }

    const options: number[] = [];

    chosenPreSpec.sections.forEach((section: PreSpecSection) => {
      if (section.priceOptions.length) {
        section.priceOptions.forEach((option: number) => {
          options.push(option);
        });
      }
    });
    return options;
  },
  preSpecPriceOptionsBySection: (state) => {
    if (!state.preSpec) {
      return [];
    }
    const filteredSections = state.preSpec.sections.filter(
      (section: PreSpecSection) => section.priceOptions.length
    );
    return filteredSections.map((section: PreSpecSection) => {
      return {
        sectionId: section.sectionId,
        priceOptions: section.priceOptions
      };
    });
  },
  preSpecDefaultOptions: (state) => {
    if (!state.preSpec) {
      return [];
    }
    return state.preSpec.sections.map((section: PreSpecSection) => section.defaultOption);
  },
  preSpecSectionIds: (state): number[] => {
    if (!state.preSpec) {
      return [];
    }
    const filteredSections = state.preSpec.sections.filter(
      (section: PreSpecSection) => section.priceOptions.length
    );
    return filteredSections.map((section: PreSpecSection) => section.sectionId);
  },
  preSpecSections: (state) => state.preSpec ? state.preSpec.sections : [],
  getPreSpecPriceOptionById: (state, getters) => (id: number) => {
    if (!state.preSpec) {
      return null;
    }
    return getters.preSpecPriceOptions.find(
      (preSpecPriceOptionId: number) => preSpecPriceOptionId === id
    );
  },
  isPreSpec: (state) => {
    return !!state.preSpec;
  },
  optionInPreSpecPriceOptions: (state, getters) => (optionId: number) => {
    if (!getters.preSpecPriceOptions) {
      return null;
    }
    return getters.preSpecPriceOptions.includes(optionId);
  }
};

export default getters;
