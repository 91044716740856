<template>
  <div class="product align-center w-100 pb20" v-observe-visibility="visibilityChanged">
    <div class="product__icons">
      <AddToWishlist :product="product">
        <div
          class="product__icon"
          :class="{'product__icon--active': isOnWishlist }"
          :title="isOnWishlist ? $t('Remove') : $t('Add to favorite') "
        >
          <i class="material-icons">{{ favoriteIcon }}</i>
        </div>
      </AddToWishlist>
      <AddToCompare :product="product">
        <div
          class="product__icon"
          :class="{'product__icon--active':isOnCompare } "
          :title="isOnCompare ? $t('Remove from compare') : $t('Add to compare')"
        >
          <i class="material-icons">compare</i>
        </div>
      </AddToCompare>
    </div>
    <router-link
      class="block no-underline product-link align-left"
      :to="productLink"
      data-testid="product-link"
      :data-stock-status="isInStock ? 'instock' : 'outofstock'"
    >
      <div
        class="product-cover bg-cl-white"
        :class="[{ sale: labelsActive && isOnSale }, { new: labelsActive && isNew }]"
      >
        <product-image
          class="product-cover__thumb"
          :image="thumbnailObj"
          :alt="product.name | htmlDecode"
          :calc-ratio="false"
          data-testid="productImage"
        />
      </div>
      <div class="product-details">
        <h4 class="mt10 product-details__name" v-if="!onlyImage">
          <span class="manufacturer">{{ getAttrOptionLabel('manufacturer', product.manufacturer) }} </span>
          <span class="name">{{ product.name | htmlDecode }}</span>
        </h4>

        <div class="product-details__swatches">
          <product-color-switcher
            v-if="product.configurable_options"
            @mouseover="changeImage($event)"
            :children="product.configurable_options"
          />
        </div>

        <PriceWasSave :product="product" :text-size="'text-base'" />

        <!--add price for display purpose-->

        <div class="mb15 mt5 uppercase text-color-secondary product-rating w-100 product-details__reviews"
             v-if="product.reviews"
        >
          <div class="review-rating">
            <div class="rating-summary">
              <span :style="{width: product.reviews.rating+'%'} " />
            </div>
          </div>
        </div>

        <!--{{ product }}-->
      </div>
    </router-link>
  </div>
</template>

<script>
import rootStore from '@vue-storefront/core/store'
import PriceWasSave from 'theme/components/theme/bikebuilder/external/PriceWasSave'
import {ProductTile} from '@vue-storefront/core/modules/catalog/components/ProductTile.ts'
import config from 'config'
import ProductImage from './ProductImage'
import AddToWishlist from 'theme/components/core/blocks/Wishlist/AddToWishlist'
import AddToCompare from 'theme/components/core/blocks/Compare/AddToCompare'
import {IsOnWishlist} from '@vue-storefront/core/modules/wishlist/components/IsOnWishlist'
import {IsOnCompare} from '@vue-storefront/core/modules/compare/components/IsOnCompare'
import {optionLabel} from '@vue-storefront/core/modules/catalog/helpers/optionLabel'
import ProductColorSwitcher from 'theme/components/core/ProductColorSwitcher'
import {currentStoreView} from '@vue-storefront/core/lib/multistore'
import {mapGetters} from 'vuex'
import { formatProductLink } from '@vue-storefront/core/modules/url/helpers'

export default {
  name: 'ProductTile',
  mixins: [ProductTile, IsOnWishlist, IsOnCompare],
  components: {
    ProductImage,
    AddToWishlist,
    AddToCompare,
    PriceWasSave,
    ProductColorSwitcher
  },
  props: {
    labelsActive: {
      type: Boolean,
      default: true
    },
    onlyImage: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      thumbnailImage: {
        src: null,
        loading: null
      }
    }
  },
  computed: {
    ...mapGetters({
      localisePrice: 'ayko_localisation/localisePrice'
    }),
    isInStock () {
      return this.product.stock && this.product.stock.stock_status
    },
    thumbnailObj () {
      return {
        src: this.thumbnailImage.src ? this.thumbnailImage.src : this.thumbnail,
        loading: this.thumbnailImage.loading ? this.thumbnailImage.loading : this.thumbnail
      }
    },
    favoriteIcon () {
      return this.isOnWishlist ? 'favorite' : 'favorite_border'
    },
    getAttrOptionLabel () {
      return (attributeKey, optionId) => {
        return optionLabel(this.$store.state.attribute, {
          attributeKey: attributeKey,
          searchBy: 'code',
          optionId: optionId
        })
      }
    },
    calculateSavePrice () {
      let getPriceMsrp, getPrice, getFinalPrice, getHighestPrice, getSavePrice;
      getPriceMsrp = this.product.msrp;
      getPrice = this.product.price;
      getFinalPrice = this.product.final_price_incl_tax;
      getHighestPrice = Math.max(getPriceMsrp, getPrice);
      if (!getHighestPrice || !getFinalPrice || getFinalPrice >= getHighestPrice) {
        return null;
      }
      getSavePrice = getFinalPrice / getHighestPrice;
      getSavePrice = Math.round((1 - getSavePrice) * 100);
      return getSavePrice;
    },
    storeView () {
      return currentStoreView()
    },
    productLink () {
      return formatProductLink(this.product, currentStoreView().storeCode)
    }
  },
  methods: {
    findVariationImage (value_index) {
      var opt = null;
      if (
        this.product.type_id === 'configurable' &&
        this.product.configurable_children &&
        this.product.configurable_children.length > 0
      ) {
        this.product.configurable_children.forEach((option) => {
          if (parseInt(option.color) === parseInt(value_index)) {
            opt = option;
          }
        });
      }
      return opt;
    },
    changeImage (variation) {
      this.thumbnailImage.src = this.getThumbnail(this.findVariationImage(variation.value_index).image, config.products.thumbnails.width, config.products.thumbnails.height);
    },
    onProductPriceUpdate (product) {
      if (product.sku === this.product.sku) {
        Object.assign(this.product, product)
      }
    },
    visibilityChanged (isVisible, entry) {
      if (
        isVisible &&
        config.products.configurableChildrenStockPrefetchDynamic &&
        config.products.filterUnavailableVariants &&
        this.product.type_id === 'configurable' &&
        this.product.configurable_children &&
        this.product.configurable_children.length > 0
      ) {
        const skus = [this.product.sku]
        for (const confChild of this.product.configurable_children) {
          const cachedItem = rootStore.state.stock.cache[confChild.id]
          if (typeof cachedItem === 'undefined' || cachedItem === null) {
            skus.push(confChild.sku)
          }
        }
        if (skus.length > 0) {
          rootStore.dispatch('stock/list', {skus: skus}) // store it in the cache
        }
      }
    }
  },
  beforeMount () {
    this.$bus.$on('product-after-priceupdate', this.onProductPriceUpdate)
  },
  beforeDestroy () {
    this.$bus.$off('product-after-priceupdate', this.onProductPriceUpdate)
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/animations/transitions';
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

$bg-secondary: color(secondary, $colors-background);
$border-secondary: color(secondary, $colors-border);
$color-white: color(white);

.product {
  position: relative;
  @media (max-width: 767px) {
    padding-bottom: 10px;
  }

  &__icons {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    padding-top: 10px;
  }

  &__icon {
    padding-top: 10px;
    opacity: 0;
    z-index: 2;
    transition: 0.3s opacity $motion-main;

    &--active {
      opacity: 1;
    }
  }

  &:hover {
    .product__icon {
      opacity: 1;
    }
  }
}

.price-original {
  text-decoration: line-through;
}

%label {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: $border-secondary;
  text-transform: uppercase;
  color: $color-white;
  font-size: 12px;
}

.product-cover {
  &__thumb {
    padding-bottom: calc(143.88% / (164.5 / 100));
    @media screen and (min-width: 768px) {
      padding-bottom: calc(300% / (276.5 / 100));
    }
    opacity: 1;
    will-change: opacity, transform;
    transition: 0.3s opacity $motion-main, 0.3s transform $motion-main;
  }

  @media screen and (min-width: 768px) {
    &:hover {
      .product-cover__thumb {
        opacity: 1;
        transform: scale(1.1);
      }

      &.sale::after,
      &.new::after {
        opacity: 0.8;
      }
    }
  }

  &.sale {
    &::after {
      @extend %label;
      content: 'Sale';
    }
  }

  &.new {
    &::after {
      @extend %label;
      content: 'New';
    }
  }
}
</style>
