import pageBuilderBlocks from "../helpers/blocks-query";

export default function query(site, slug) {
  return {
    query: `
      query ($site: [String], $slug: [String]) {
        entry(section: "help", site: $site, slug: $slug) {
          ... on help_article_Entry {
            id
            title
            metaTitle
            metaDescription
            metaKeywords
            summary
            typeHandle
            ${pageBuilderBlocks}
          }
          ... on help_faq_Entry {
            id
            title
            metaTitle
            metaDescription
            metaKeywords
            summary
            typeHandle
            body
            helpCategory {
              id
              slug
              title
            }
          }
        }
      }
    `,
    variables: {
      site,
      slug
    }
  };
}
