import { Module } from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import RootState from 'core/types/RootState';
import PreSpecState from './types/PreSpecState';

export const preSpecStore: Module<PreSpecState, RootState> = {
  namespaced: true,

  state: {
    preSpec: null,
    preSpecs: [],
    preSpecProducts: [],
    siblingProducts: [],
    error: null
  },

  mutations,
  actions,
  getters
};
