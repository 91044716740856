const assets = `
... on pageBuilder_Asset {
  id
  url
}
... on misc_Asset {
  id
  url
}
... on products_Asset {
  id
  url
}
... on heros_Asset {
  id
  url
}
... on blog_Asset {
  id
  url
}
... on categories_Asset {
  id
  url
}
`;
export default assets;
