const addressQuery = `
  addresses {
    id
    firstname
    lastname
    street
    city
    region {
      region_code
      region
    }
    postcode
    country_code
    telephone
    default_shipping
    default_billing
  }
`;

const ordersQuery = `
  orders(
    pageSize: 100
  ) {
    items {
      id
      shipping_method
      payment_methods {
        name
      }
      used_certificate {
        id
        provider_name
        amount
        amount_used
        currency
        method
      }
      order_date
      increment_id
      shipping_address {
        firstname
        lastname
        street
        city
        postcode
        region
        country_code
      }
      items {
        id
        product_name
        product_sku
        product_type
        quantity_ordered
        product_sale_price
        {
          value
          currency
        }
      }
      total {
        grand_total {
          value
          currency
        }
        subtotal{
          value
          currency
        }
        total_shipping{
          value
          currency
        }
        total_tax{
          value
          currency
        }
      }
      status
    }
  }
`;

export const loginQuery = `
  mutation($email: String!, $password: String!) {
    generateCustomerToken(email: $email, password: $password) {
      accessToken
      refreshToken
    }
  }
`;

export const logoutQuery = `
  mutation revokeCustomerToken {
    revokeCustomerToken {
      result
    }
  }
`;

export const customerQuery = `
query {
  customer {
    firstname
    lastname
    email
    is_subscribed
    ${addressQuery}
    ${ordersQuery}
  }
}
`;

export function updateCustomerQuery(firstName: string, lastName: string) {
  return `
    mutation {
      updateCustomerV2(
        input: {
          firstname: "${firstName}"
          lastname: "${lastName}"
        }) {
        customer {
          firstname
          lastname
          email
          ${addressQuery}
          ${ordersQuery}
        }
      }
    }
  `;
}

export function updateCustomerEmailQuery(email: string, password: string) {
  return `
    mutation {
      updateCustomerEmail(email: "${email}", password: "${password}") {
        customer {
          firstname
          lastname
          email
          ${addressQuery}
          ${ordersQuery}
        }
      }
    }
  `;
}

export function updatePasswordQuery(password: string, newPassword: string) {
  return `
    mutation {
      changeCustomerPassword(currentPassword: "${password}", newPassword: "${newPassword}") {
        email
      }
    }
  `;
}

export function deleteCustomerAddressQuery(id: number) {
  return `
    mutation {
      deleteCustomerAddress(id: ${id})
    }
  `;
}

export function createCustomerAddressQuery(payload) {
  return `
    mutation {
      createCustomerAddress(input: {
        firstname: "${payload.firstname}"
        lastname: "${payload.lastname}"
        telephone: "${payload.telephone}"
        street: ["${payload.street}"]
        city: "${payload.city}"
        postcode: "${payload.postcode}"
        region: {
          region: "${payload.region}"
        }
        country_code: ${payload.country_code}
        default_shipping: true
        default_billing: true
      }) {
        id
      }
    }
  `;
}

export function updateCustomerAddressQuery(payload) {
  return `
    mutation {
      updateCustomerAddress(id:${payload.id}, input: {
        firstname: "${payload.firstname}"
        lastname: "${payload.lastname}"
        telephone: "${payload.telephone}"
        street: ["${payload.street}"]
        city: "${payload.city}"
        postcode: "${payload.postcode}"
        region: {
          region: "${payload.region}"
        }
        country_code: ${payload.country_code}
      }) {
        id
      }
    }
  `;
}

export function subscribeNewsletterQuery(email: string) {
  return `
    mutation {
      subscribeEmailToNewsletter(
      email: "${email}"
    ) {
      status
    }
  }
  `;
}

export function unsubscribeNewsletterQuery(email: string) {
  return `
    mutation {
      unsubscribeEmailToNewsletter(
      email: "${email}"
    ) {
      status
    }
  }
  `;
}

export function createCustomerQuery(payload) {
  return `
    mutation {
      createCustomer(
        input: {
          firstname: "${payload.firstname}"
          lastname: "${payload.lastname}"
          email: "${payload.email}"
          password: "${payload.password}"
        }
      ) {
        customer {
          firstname
          lastname
          email
          ${addressQuery}
        }
      }
    }
  `;
}

export function requestPasswordResetEmailQuery(email: string) {
  return `
    mutation {
      requestPasswordResetEmail(
        email: "${email}"
      )
    }
  `;
}

export function orderDetailsQuery(orderId: string) {
  return `
    query {
      customer {
        orders(filter: {number: {eq: "${orderId}"}}) {
          items {
            id
            used_certificate {
              id
              provider_name
              amount
              amount_used
              currency
              method
            }
            payment_methods {
              name
            }
            shipping_address {
              firstname
              lastname
              street
              city
              region
              postcode
              country_code
            }
            billing_address {
              firstname
              lastname
              street
              city
              region
              postcode
              country_code
            }
            shipping_method
            payment_methods {
              name
            }
            number
            order_date
            status
            carrier
            items {
              product_name
              entered_options {
                label
              }
              product_sku
              product_type
              product_url_key
              product_sale_price {
                value
                currency
              }
              discounts {
                amount{
                  value
                  currency
                }
                label
              }
              quantity_ordered
              quantity_shipped
            }
          }
        }
      }
    }
  `;
}
