import axios from 'axios';
import config from 'config';
// @ts-ignore
import { marked } from 'marked';
import postQuery from '../craft-queries/help/post';
import listingQuery from '../craft-queries/help/listing-query';

export async function fetchCmsData(query, token = null) {
  const url = config.craftcmsUrl;
  const fullUrl = token ? url + `?token=${token}` : url;
  try {
    const res = await axios.post(fullUrl, query);
    if (res.data.errors && res.data.errors.length) {
      console.error(res.data.errors[0]);
      throw new Error('Error with graphql api');
    }
    return res.data.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

function parseMarkdown(data) {
  if (!data) return [];
  return data.pageBuilder.map(block => {
    let newBlock = block;
    if (block.typeHandle === 'textBlock') {
      newBlock.text = marked.parse(block.text);
    }
    if (block.typeHandle === 'textByImage') {
      newBlock.text = marked.parse(block.text);
    }
    if (block.typeHandle === 'textOnImage') {
      newBlock.text = marked.parse(block.text);
    }
    return newBlock;
  });
}

export const helpStore = {
  namespaced: true,
  state: {
    post: null,
    postListing: null
  },
  mutations: {
    SET_POST (state, content) {
      state.post = content;
    },
    SET_POST_LISTING (state, content) {
      state.postListing = content;
    }
  },
  actions: {
    async updatePost ({ commit }, payload) {
      const site = payload.storeCode === 'de_de_de' ? 'ribbleDe' : 'default';
      const slug = payload.slug;
      const query = postQuery(site, slug);
     try {
        const content = await fetchCmsData(query, payload.token);
        commit('SET_POST', content);
     } catch (error) {
        console.error(error);
     }
    },
    async updatePostListing ({ commit }, payload) {
      const site = payload.storeCode === 'de_de_de' ? 'ribbleDe' : 'default';
      const slug = payload.slug;
      const query = listingQuery(site, slug);
     try {
        const content = await fetchCmsData(query, payload.token);
        commit('SET_POST_LISTING', content);
     } catch (error) {
        console.error(error);
     }
    }
  },
  getters: {
    getHelpPost: state => {
      if (!state.post || !state.post.entry) return null;
      const data = state.post.entry;
      if (data.pageBuilder && data.pageBuilder.length) {
        data.pageBuilder = parseMarkdown(data);
      }
      if (data.body) {
        data.body = marked.parse(data.body);
      }
      return data;
    },
    getHelpListing: state => state.postListing
  }
};
