export default function blogPostsCategoryListingQuery(variables) {
  return {
    query: `
		query ($site: [String], $offset: Int, $limit: Int, $category: [CategoryCriteriaInput], $categorySlug: [String]) {
			entryCount(section: "blog", relatedToCategories: $category)
			blogEntries(
				site: $site
				offset: $offset
				limit: $limit
				relatedToCategories: $category
			) {
				... on blog_default_Entry {
					id
					postDate
					slug
					title
					summary
					typeHandle
					blogFeaturedImage {
						... on blog_Asset {
							id
							url(transform: "img625x415")
						}
					}
				}
				... on blog_legacy_Entry {
					id
					postDate
					slug
					title
					summary
					typeHandle
					blogFeaturedImage {
						... on blog_Asset {
							id
							url(transform: "img625x415")
						}
					}
				}
			}
			categories(slug: $categorySlug) {
				... on blog_Category {
					id
					metaDescription
					metaKeywords
					metaTitle
					title
				}
			}
		}
  `,
    variables
  };
}
