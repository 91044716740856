export default function blogPostsListingQuery(variables) {
    return {
      query: `
      query ($site: [String], $offset: Int, $limit: Int) {
        entryCount(section: "blog")
        blogEntries(site: $site, offset: $offset, limit: $limit) {
          ... on blog_default_Entry {
            id
            postDate
            slug
            title
            summary
            typeHandle
            blogFeaturedImage {
              ... on blog_Asset {
                id
                url(transform: "img625x415")
              }
            }
          }
          ... on blog_legacy_Entry {
            id
            postDate
            slug
            title
            summary
            typeHandle
            blogFeaturedImage {
              ... on blog_Asset {
                id
                url(transform: "img625x415")
              }
            }
          }
        }
        blogListingPage: entry(slug: "blog-listing") {
    			title
    			id
          ... on blogListing_blogListing_Entry {
            metaDescription
            metaKeywords
            metaTitle
          }
        }
      }
      `,
      variables,
    };
  }
