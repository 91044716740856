import axios from 'axios';
import { ColorOptionType } from 'theme/store/configurator/color.types';

export async function fetchData(
  query: string,
  variables = {},
  url: string,
  storeCode = 'default',
  isMagento = true
) {
  try {
    const axiosConfig = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    if (isMagento) {
      axiosConfig.headers['Store'] = storeCode;
    }
    const res = await axios.post(url, { query, variables }, axiosConfig);
    if (res.data.errors && res.data.errors.length) {
      console.error(res.data.errors[0]);
      throw new Error('Error with graphql api:' + url);
    }
    return res.data.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}
export const trimText = (text: string, length: number): string => {
  const words = text.split(' ');
  let result = '';
  let currentLength = 0;

  for (const word of words) {
    if (currentLength + word.length + result.length <= length) {
      result += word + ' ';
      currentLength += word.length + 1;
    } else {
      break;
    }
  }
  return result.trim();
};

export const trimName = (item: any): string => {
  if (!item || !item.product || !item.product.name) {
    return;
  }
  return trimText(item.product.name, 80);
};

export const optionNameMap = (name: string, mapType: string): string => {
  const optionMapSlug = {
    frameColor: 'frame_colour',
    decalColor: 'decal_colour',
    accentColor1: 'accent_colour_1',
    accentColor2: 'accent_colour_2',
    gradientColor: 'gradient_colour',
    gradientAngle: 'gradient_angle',
    paintFinish: 'paint_finish'
  };
  const optionNameMap = {
    [ColorOptionType.FrameColour]: 'frameColor',
    [ColorOptionType.GradientColour]: 'gradientColor',
    [ColorOptionType.DecalColour]: 'decalColor',
    [ColorOptionType.AccentColour1]: 'accentColor1',
    [ColorOptionType.GradientAngle]: 'gradientAngle',
    [ColorOptionType.PaintFinish]: 'paintFinish',
    'Top Tube and Fork Logo':'decalColor',
    'Frame and Fork Logo':'decalColor'
  };
  return mapType === 'slug' ? optionMapSlug[name] : optionNameMap[name];
};
