export default function promoQuery(site) {
  return {
    query: `
      query ($site: [String]) {
        entries(site: $site, section: "promotions") {
          ... on promotions_default_Entry {
            id
            slug
            sectionHandle
            heading
            shortFormText
            longFormText
            textPosition
            imagePosition
            ctaText
            ctaUrl
            ctaMobile
            imageWidthDesktop
            imageWidthMobile
            blockTextColour
            blockBackgroundColour
            mobileImage {
              ... on blog_Asset {
                url
              }
              ... on misc_Asset {
                url
              }
              ... on misc_Asset {
                url
              }
              ... on pageBuilder_Asset {
                url
              }
              ... on products_Asset {
                url
              }
              ... on categories_Asset {
                url
              }
            }
            desktopImage {
              ... on blog_Asset {
                url
              }
              ... on misc_Asset {
                url
              }
              ... on misc_Asset {
                url
              }
              ... on pageBuilder_Asset {
                url
              }
              ... on products_Asset {
                url
              }
              ... on categories_Asset {
                url
              }
            }
          }
        }
      }
    `,
    variables: {
      site
    }
  };
}
