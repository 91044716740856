<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import { mapState } from 'vuex';
const DefaultLayout = () =>
  import(/* webpackChunkName: "vsf-layout-default" */ './layouts/Default');
const EmptyLayout = () =>
  import(/* webpackChunkName: "vsf-layout-empty" */ './layouts/Empty');
const MinimalLayout = () =>
  import(/* webpackChunkName: "vsf-layout-minimal" */ './layouts/Minimal');
const BlankLayout = () =>
  import(/* webpackChunkName: "vsf-layout-blank" */ './layouts/Blank');

export default {
  components: {
    DefaultLayout,
    EmptyLayout,
    MinimalLayout,
    BlankLayout
  },
  data() {
    return {
      ordersData: []
    };
  },
  computed: {
    ...mapState({
      overlayActive: (state) => state.ui.overlay
    }),
    layout() {
      return `${
        this.$route.meta && this.$route.meta.layout
          ? this.$route.meta.layout
          : 'default'
      }-layout`;
    }
  },
  mounted() {
    // add a go in store style tag to head of document and load script
    document.head.insertAdjacentHTML(
      'beforeend',
      '<style id="gis-styles"></style>'
    );
    const gisScript = document.createElement('script');
    gisScript.setAttribute('src', 'https://gis.goinstore.com/gis/script');
    gisScript.setAttribute('defer', true);
    document.head.appendChild(gisScript);
  }
};
</script>
