import pageBuilderBlocks from './helpers/blocks-query';

export default function pageBuilderQuery(site, uri) {
  return {
    query: `
      query ($site: [String], $uri: [String]) {
        pageBuilderEntries(site: $site, uri: $uri) {
          ... on pageBuilder_default_Entry {
            title
            darkMode
            pageBackground {
              ... on misc_Asset {
                id
                url
              }
            }
            metaTitle
            metaDescription
            metaKeywords
            robots
            ${pageBuilderBlocks}
          }
        }
      }
    `,
    variables: {
      site,
      uri
    }
  };
}
