export default {
  BIKE_PRODUCT_TYPE: 'ribble_bikebuilder',
  COLOUR_GRADIENT_ANGLE: 'colour_gradient_angle',
  COLOUR_MATCH_COCKPIT: 'colour_match_cockpit',
  PAINT_FINISH: 'paint_finish',
  BIKE_PAINT_FINISH: 'bike_paint_finish',
  GLOSS: 'Gloss',
  METALLIC: 'Metallic',
  MATT: 'Matt',
  GLITTER: 'Glitter',
  SIMPLE: 'simple',
  CONFIGURABLE: 'configurable',
  BUNDLE: 'bundle',
  GROUPED: 'grouped',
  FRAME: 'frame',
  FORK: 'fork',
  CUSTOM_COLOUR: 'customcolor',
  IGNOREATTRIBUTES: ['size', 'color'],
  CUSTOM_COLOUR_FALLBACK_TIME: 28,
  SIZE_MAPPING_CACHE_KEY: 'sizeMappingId',
  FRAME_SIZE: 'frame_size_bbv4',
  REQUIRED_SECTION: 48,
  HEADSET_SECTION: 4,
  COLOUR_PACK_SECTION: 51
};
