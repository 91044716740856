import { Module } from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import RootState from 'core/types/RootState';
import CustomColorState from './types/CustomColorState';

export const customColourStore: Module<CustomColorState, RootState> = {
  namespaced: true,

  state: {
    bundleOptions: [],
    gradientAngle: null,
    cockpitMatch: false,
    showBike: true
  },

  mutations,
  actions,
  getters
};
