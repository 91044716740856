export default function mainMenuQuery(site) {
  return {
    query: `
      query ($site: [String]) {
        siteMessagesEntries(site: $site) {
          ... on siteMessages_default_Entry {
            id
            lft
            message
          }
        }
        mainMenuEntries(site: $site) {
          ... on mainMenu_levelOne_Entry {
            id
            title
            level
            lft
            ctaLink
            blockBackgroundColour
            blockTextColour
            typeHandle
            headingUrl
            trending {
              ... on trending_default_Entry {
                id
                sku
                title
                heading
                pageUrl
                summary
                trendingLabel
                productImage {
                  ... on products_Asset {
                    id
                    url(transform: "imgAutox256")
                  }
                }
              }
            }
          }
          ... on mainMenu_levelTwo_Entry {
            id
            title
            level
            lft
            typeHandle
            headingUrl
            description
            parent {
              id
            }
          }
          ... on mainMenu_levelThree_Entry {
            id
            title
            lft
            level
            pageUrl
            typeHandle
            externalUrl
            parent {
              id
            }
          }
        }
      }
    `,
    variables: {
      site
    }
  };
}
