// @ts-nocheck
// @eslint-ignore-file

const initialState = {
  image: {
    image: ''
  },
  imageZoom: false,
  configuratorStep: 0,
  steps: 5,
  finished: false
};
export const appStore = {
  namespaced: true,
  state: {
    ...initialState
  },
  actions: {
    setImage: ({ commit }, image) => {
      commit('setImage', image);
    },
    setSteps: ({ commit }, step) => {
      commit('setSteps', step);
    },
    clearImage: ({ commit }, item) => {
      commit('setImage', { image: '' });
    },
    setInitialState: async ({ commit }) => {
      commit('setInitialState');
    }
  },
  mutations: {
    setImage: (state, value) => {
      state.image = value;
    },
    clearImage: (state) => {
      state.image = { image: '' };
    },
    toggleImageZoom: (state) => {
      state.imageZoom = !state.imageZoom;
    },
    setConfiguratorStep: (state, value) => {
      state.configuratorStep = value;
    },
    nextConfiguratorStep: (state) => {
      if (state.configuratorStep < state.steps) {
        state.configuratorStep++;
      }
    },
    setFinished: (state, value) => {
      state.finished = value;
    },
    setSteps: (state, value) => {
      state.steps = value;
    },
    setInitialState: (state) => {
      Object.assign(state, initialState);
    }
  },
  getters: {
    getImageZoom: (state) => state.imageZoom,
    getImage: (state) => state.image,
    getFinished: (state) => state.finished,
    getSteps: (state) => state.steps,
    getConfiguratorStep: (state) => state.configuratorStep,
    getIsLastStep: (state) => state.configuratorStep === state.steps
  }
};
