export const builderUiStore = {
  namespaced: true,
  state: {
    specReviewNavOpen: false,
    sectionListOpen: false,
    fullScreen: false,
    reviewOpen: false,
    showShareModal: false,
    showDeliveryModal: false,
    showCartFullSpec: false,
    selectedCartUid: null
  },
  mutations: {
    SET_SECTION_LIST(state) {
      state.sectionListOpen = !state.sectionListOpen;
    },
    SET_FULL_SCREEN(state) {
      state.fullScreen = !state.fullScreen;
    },
    SET_SPEC_REVIEW_NAV(state) {
      state.specReviewNavOpen = !state.specReviewNavOpen;
    },
    SET_REVIEW(state) {
      state.reviewOpen = !state.reviewOpen;
    },
    SET_SHARE_MODAL(state) {
      state.showShareModal = !state.showShareModal;
    },
    SET_DELIVERY_MODAL(state) {
      state.showDeliveryModal = !state.showDeliveryModal;
    },
    SET_CUSTOM_COLOR_MODAL(state, label) {
      state.customColorModalOpen = !state.customColorModalOpen;
    },
    SET_CART_FULL_SPEC(state) {
      state.showCartFullSpec = !state.showCartFullSpec;
    },
    SET_SELECTED_CART_UID(state, uid) {
      state.selectedCartUid = uid;
    }
  },
  actions: {
    toggleCustomColorModal({ commit }) {
      commit('SET_CUSTOM_COLOR_MODAL');
    },
    toggleSectionList({ commit }) {
      commit('SET_SECTION_LIST');
    },
    toggleFullScreen({ commit }) {
      commit('SET_FULL_SCREEN');
    },
    toggleSpecReviewNav({ commit }) {
      commit('SET_SPEC_REVIEW_NAV');
    },
    toggleShareModal({ commit }) {
      commit('SET_SHARE_MODAL');
    },
    toggleDeliveryModal({ commit }) {
      commit('SET_DELIVERY_MODAL');
    },
    toggleReviewOpen({ commit }) {
      commit('SET_REVIEW');
    },
    toggleFullSpecCartModal({ commit }, selectedCartUid = null) {
      if (selectedCartUid) {
        commit('SET_SELECTED_CART_UID', selectedCartUid);
      } else {
        commit('SET_SELECTED_CART_UID', null);
      }
      commit('SET_CART_FULL_SPEC');
    }
  },
  getters: {
    sectionListOpen: (state) => state.sectionListOpen,
    reviewOpen: (state) => state.reviewOpen,
    showShareModal: (state) => state.showShareModal,
    showDeliveryModal: (state) => state.showDeliveryModal,
    showCartFullSpecModal: (state) => state.showCartFullSpec,
    getSelectedCartUid: (state) => state.selectedCartUid
  }
};
