<template>
  <div class="links">
    <div class="between-md" v-for="(productLink, index) in products" :key="index">
      <div class="link-container" v-if="productLink.product">
        <div class="row middle-xs h4 link-details">
          <span class="price-details" v-if="productLink.product.special_price && productLink.product.price_incl_tax && productLink.product.original_price_incl_tax">
            <span class="m0">
              {{ productLink.product.name | htmlDecode }}
            </span>
            <span class="price">
              <span class="price-special">{{ localisePrice(productLink.product.price_incl_tax, storeView) }}</span>&nbsp;
              <span class="price-original">{{ localisePrice(productLink.product.original_price_incl_tax, storeView) }}</span>
            </span>
          </span>
          <span class="price-details" v-if="!productLink.product.special_price && productLink.product.price_incl_tax">
            <span class="m0">
              {{ productLink.product.name | htmlDecode }}
            </span>
            <span class="price">
              {{ localisePrice(productLink.product.price_incl_tax, storeView) }}
            </span>
          </span>
        </div>

        <div v-if="productLink.product" class="link-qty cl-bg-tertiary ">
          <p class="m0 text">
            {{ $t('Qty') }}
          </p>
          <input
            type="text"
            class="product-qty"
            min="1"
            autofocus
            v-model.number="productLink.product.qty"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import {mapGetters} from 'vuex'

export default {
  props: {
    products: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      localisePrice: 'ayko_localisation/localisePrice'
    }),
    storeView () {
      return currentStoreView()
    }
  }
}
</script>

<style scoped>
.product-qty {
  border-style: solid;
  border-width: 0 0 1px 0;
  width: 90px;
}
</style>
