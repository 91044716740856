import axios from 'axios';
import { allData, getInstagramBySku } from './oculizm-queries';

export async function fetchData(query) {
  try {
    const res = await axios.get(query);
    if (res.data.errors && res.data.errors.length) {
      console.error(res.data.errors[0]);
      throw new Error('Error with graphql api');
    }
    return res.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const oculizmStore = {
  namespaced: true,
  state: {
    oculizmData: null
  },
  mutations: {
    SET_OCULIZM_DATA(state, content) {
      state.oculizmData = content;
    }
  },
  actions: {
    async updateOculizmData({ commit }, sku = null) {
      try {
        const payload = sku ? getInstagramBySku(sku) : allData(96);
        const content = await fetchData(payload);
        commit('SET_OCULIZM_DATA', content);
      } catch (error) {
        console.error(error);
      }
    }
  },
  getters: {
    allOculizmPosts: state => {
      if (state.oculizmData && state.oculizmData.data && state.oculizmData.data.posts) {
        return state.oculizmData.data.posts.nodes;
      } else {
        return [];
      }
    }
  }
};
