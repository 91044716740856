import pageBuilderBlocks from "./helpers/blocks-query";
import blogPostMeta from "./helpers/blog-post-meta";

export default function blogPostQuery(site, slug) {
  return {
    query: `
      query ($site: [String], $slug: [String]) {
        blogEntries(site: $site, slug: $slug) {
          ... on blog_legacy_Entry {
            ${blogPostMeta}
            body
          }
          ... on blog_default_Entry {
            ${blogPostMeta}
            ${pageBuilderBlocks}
          }
        }
      }
    `,
    variables: {
      site,
      slug
    }
  };
}
