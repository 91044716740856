import axios from 'axios';
import config from 'config';

const productQuery = `
query products($url_key: String!) {
	products(filter: { url_key: { in: [$url_key] } }) {
		items {
			sku
			id
      uid
      name
      url_key
      meta_title
      subtitle
      sizes_available
      oos_cta_message
      full_spec
      sizes_available
      oos_cta_message
      category_level_1
      category_level_2
      category_level_3
      image {
				url
			}
      colour_ways {
        default
        hex
        colour_text
        sku
        imageUrl
      }
			meta_description
			meta_keyword
      banned_shipping_countries
      description {
        html
      }
      short_description {
        html
      }
      price_range {
        default_price {
          regular_price {
            value
            currency
          }
          final_price {
            value
            currency
          }
        }
        default_special_price {
          regular_price {
            value
            currency
          }
          final_price{
            value
            currency
          }
        }
      }
      upsell_products {
        id
        name
        manufacture
        sku
        stock_status
        price_range {
          maximum_price {
            regular_price {
              value
              currency
            }
            final_price {
              value
              currency
            }
          }
        }
        image {
          url
        }
      }
			linked_specifications {
				sku
				uid
				name
        subtitle
				url_key
        banned_shipping_countries
        image {
          url
        }
        colour_ways {
					default
					hex
					colour_text
					sku
					imageUrl
				}
				product_list_description
				price_range {
					default_price {
						regular_price {
							value
							currency
						}
						final_price {
							value
							currency
						}
					}
          default_special_price {
						regular_price {
							value
							currency
						}
						final_price{
							value
							currency
						}
					}
				}
			}
		}
	}
}
`;

async function fetchMagentoData(
  query: string,
  variables = {},
  rootGetters,
  colourVariant = ''
) {
  try {
    const axiosConfig = {
      headers: {
        'Content-Type': 'application/json',
        'Content-Currency': rootGetters['esw/getUserCurrency'],
        'ESW-Country': rootGetters['esw/getUserCountry'],
        'gcdn-force': 1
      }
    };
    if (colourVariant) {
      axiosConfig.headers['Colour-Variant'] = colourVariant;
    }
    const url = config.stellateUrl;
    const res = await axios.post(url, { query, variables }, axiosConfig);
    if (res.data.errors && res.data.errors.length) {
      console.error(res.data.errors[0]);
      throw new Error('Error with graphql api:' + url);
    }
    return res.data.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const graphqlProductStore = {
  namespaced: true,
  state: {
    product: null
  },
  mutations: {
    SET_PRODUCT(state, data) {
      state.product = data.products.items[0];
    }
  },
  actions: {
    async fetchProduct({ commit, rootGetters }, payload) {
      const variables = { url_key: payload.slug };
      const colourVariant = payload.colourVariant || '';

      try {
        const data = await fetchMagentoData(
          productQuery,
          variables,
          rootGetters,
          colourVariant
        );
        if (!data || !data.products || !data.products.items.length) return;
        commit('SET_PRODUCT', data);
      } catch (error) {
        console.error(error);
      }
    }
  },
  getters: {
    getProduct: (state) => state.product,
    getProductRegularPrice: (state) => {
      if (!state.product) return 0;
      return state.product.price_range.default_price.regular_price.value;
    },
    getProductFinalPrice: (state) => {
      if (!state.product) return 0;
      return state.product.price_range.default_price.final_price.value;
    },
    getProductSpecialPrice: (state) => {
      if (!state.product) return 0;
      return state.product.price_range.default_special_price.final_price.value;
    },
    getRelatedProducts: (state) => {
      if (!state.product) return [];
      return state.product.related_products;
    },
    getLinkedSpecProducts: (state) => {
      if (!state.product) return [];
      return state.product.linked_specifications.sort((a, b) => {
        return (
          b.price_range.default_price.final_price.value -
          a.price_range.default_price.final_price.value
        );
      });
    },
    getChildColourWays: (state) => {
      if (!state.product) return [];
      const colourWays = state.product.colour_ways;
      return colourWays && colourWays.length ? colourWays : [];
    },
    getCheapestLinkedProduct: (state) => {
      if (
        !state.product ||
        !state.product.linked_specifications ||
        !state.product.linked_specifications.length
      )
        return null;
      const linkedProducts = state.product.linked_specifications;
      return linkedProducts
        .map((product) => {
          const defaultPrice = product.price_range.default_price.final_price.value;
          const specialPrice = product.price_range.default_special_price && product.price_range.default_special_price.final_price.value > 0
        ? product.price_range.default_special_price.final_price.value 
        : undefined;

          const finalPrice = specialPrice !== undefined && specialPrice < defaultPrice 
            ? specialPrice 
            : defaultPrice;

          return {
            ...product,
            price: finalPrice
          };
        })
        .sort((a, b) => a.price - b.price)[0];
    },
    hasColourVariants: (state) => {
      if (!state.product) return false;
      const colourWays = state.product.colour_ways
        ? state.product.colour_ways
        : [];
      if (colourWays.length) return true;
      const linkedProducts = state.product.linked_specifications;
      return !!(
        linkedProducts[0] &&
        linkedProducts[0].colour_ways &&
        linkedProducts[0].colour_ways.length
      );
    },
    getFullSpec: (state) => {
      if (!state.product || !state.product.full_spec) return null;
      return JSON.parse(state.product.full_spec);
    }
  }
};
