import Vue from 'vue';
import axios from 'axios';
import dayjs from 'dayjs';
import config from 'config';
import Cookies from 'js-cookie';
import { getProducts } from './helpers';
import VueGtm from '@gtm-support/vue2-gtm';
import { router } from '@vue-storefront/core/app';
import {
  loginQuery,
  logoutQuery,
  customerQuery,
  orderDetailsQuery,
  updateCustomerQuery,
  updatePasswordQuery,
  createCustomerQuery,
  updateCustomerEmailQuery,
  subscribeNewsletterQuery,
  deleteCustomerAddressQuery,
  createCustomerAddressQuery,
  updateCustomerAddressQuery,
  unsubscribeNewsletterQuery,
  requestPasswordResetEmailQuery
} from './magento-queries/graphql-account';

declare global {
  interface Window {
    NOIBUJS?: any;
  }
}

const availableCountries = config.ayko.localisation.availableCountries;

const m2GraphqlUrl = config.m2GraphqlUrl;

const setTokens = (accessToken: string, refreshToken: string) => {
  const now = dayjs();
  const oneHour = now.add(1, 'hour');
  const oneHourUnix = oneHour.unix();
  Cookies.set('customer_token', accessToken, {
    expires: oneHour.toDate(),
    secure: true,
    sameSite: 'strict'
  });
  Cookies.set('customer_refresh_token', refreshToken, {
    expires: oneHour.toDate(),
    secure: true,
    sameSite: 'strict'
  });
  Cookies.set('customer_expire_time', oneHourUnix, {
    expires: oneHour.toDate(),
    secure: true,
    sameSite: 'strict'
  });
};

function googleTagManagerEventOrderConfirmation(order) {
  const GTM: typeof VueGtm = (Vue as any).gtm;

  GTM.trackEvent({
    ecommerce: {
      purchase: {
        actionField: {
          id: order.increment_id,
          affiliation: 'Ribble',
          revenue: order.base_grand_total,
          tax: order.tax_amount,
          shipping: order.base_shipping_incl_tax,
          coupon: ''
        },
        // 'products': getProducts(order.items),
        currencyCode: order.order_currency_code
      }
    }
  });

  GTM.trackEvent({
    event: 'confirmation',
    transactionId: order.increment_id,
    // customerEmail: order.customer_email,
    // this features on the confirmation page and is set to yes if the order contains a bike
    transactionContainsBike: !!order.items.find(
      (item) =>
        item.product_type === 'ribble_bikebuilder' ||
        (item.product_type === 'simple' && item.product_sku.includes('RIBBSTK'))
    ),
    // this features on the confirmation page and states the payment method used"
    transactionPayment: order.payment_methods[0].name,
    // this features on the confirmation page and states the currency used
    transactionCurrency: 'GBP',
    transactionBaseCurrency: 'GBP',
    // transactionPromoCodes: order.base_currency_code,
    transactionShippingAmount: order.total.total_shipping.value,
    transactionTotal: order.total.grand_total.value,
    transactionTax: order.total.total_tax.value,
    transactionBaseShippingAmount: order.total.total_shipping.value,
    transactionBaseTotal: order.total.grand_total.value,
    transactionBaseTax: order.total.total_tax.value
    // transactionProducts: getProducts(order.items)
  });
}

export const graphqlAccountStore = {
  namespaced: true,
  state: {
    error: '',
    message: '',
    customer: null,
    loading: false,
    showOrders: false,
    showProfile: true,
    orderDetails: null,
    showShipping: false,
    showNewsletter: false,
    showEditProfile: false,
    showEditShipping: false,
    redirectCheckout: false,
    showAccountDropdown: false,
    checkoutRedirectVersion: ''
  },
  mutations: {
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_CUSTOMER(state, customer) {
      state.customer = customer;
    },
    CLEAR_CUSTOMER_TOKEN() {
      Cookies.remove('customer_token');
    },
    SET_SHOW_ORDERS(state, payload) {
      state.showOrders = payload;
    },
    SET_SHOW_PROFILE(state, payload) {
      state.showProfile = payload;
    },
    SET_SHOW_SHIPPING(state, payload) {
      state.showShipping = payload;
    },
    SET_SHOW_NEWSLETTER(state, payload) {
      state.showNewsletter = payload;
    },
    SET_EDIT_SHIPPING(state) {
      state.showEditShipping = !state.showEditShipping;
    },
    SET_EDIT_PROFILE(state) {
      state.showEditProfile = !state.showEditProfile;
    },
    SET_MESSAGE(state, message) {
      state.message = message;
    },
    CLEAR_MESSAGE(state) {
      state.message = '';
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    CLEAR_ERROR(state) {
      state.error = '';
    },
    SET_REDIRECT_CHECKOUT(state, payload) {
      state.redirectCheckout = payload;
    },
    SET_CUSTOMER_ORDER(state, payload) {
      state.orderDetails = payload;
    },
    SET_SHOW_ACCOUNT_DROPDOWN(state) {
      state.showAccountDropdown = true;
    },
    CLEAR_HIDE_ACCOUNT_DROPDOWN(state) {
      state.showAccountDropdown = false;
    },
    SET_CHECKOUT_REDIRECT_VERSION(state, payload) {
      state.checkoutRedirectVersion = payload;
    }
  },
  actions: {
    async fetchData({ commit }, query) {
      commit('SET_ERROR', '');
      let res;
      const token = Cookies.get('customer_token') as string | undefined;
      const url = m2GraphqlUrl; // config.m2GraphqlUrl;
      if (token) {
        res = await axios.post(
          url,
          { query },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            }
          }
        );
      } else {
        res = await axios.post(url, { query });
      }
      if (res.data.errors && res.data.errors.length) {
        throw new Error(res.data.errors[0].message);
      }
      return res;
    },
    async checkCustomerToken({ dispatch }) {
      const expiryTime = Cookies.get('customer_expire_time');
      if (!expiryTime) return;
      const now = dayjs();
      const diff = dayjs.unix(expiryTime).diff(now, 'minute');
      if (diff < 5) {
        await dispatch('renewCustomerToken');
      }
    },
    async renewCustomerToken({ dispatch }) {
      const refreshToken = Cookies.get('customer_refresh_token');
      if (!refreshToken) return;
      const query = `
        mutation {
          reissueCustomerToken(refreshToken: ${refreshToken}) {
            accessToken
            refreshToken
          }
        }
      `;
      const res = await dispatch('fetchData', query);
      const accessToken = res.data.data.reissueCustomerToken.accessToken;
      const newRefreshToken = res.data.data.reissueCustomerToken.refreshToken;
      setTokens(accessToken, newRefreshToken);
      return accessToken;
    },
    async login({ commit, dispatch, getters, rootGetters }, payload) {
      try {
        commit('SET_LOADING', true);
        commit('SET_ERROR', '');
        const guestCartId = Cookies.get('cart_id');
        const cartItems = rootGetters['graphqlCart/getCartItems'];
        if (guestCartId) {
          await dispatch('graphqlCart/setTempGuestCartId', guestCartId, {
            root: true
          });
        }
        const variables = { email: payload.email, password: payload.password };
        const url = m2GraphqlUrl; // config.m2GraphqlUrl;
        const query = loginQuery;
        const res = await axios.post(url, { query, variables });
        if (res.data.errors && res.data.errors.length) {
          throw new Error(res.data.errors[0].message);
        }
        const accessToken = res.data.data.generateCustomerToken.accessToken;
        const refreshToken = res.data.data.generateCustomerToken.refreshToken;
        setTokens(accessToken, refreshToken);
        await dispatch('fetchCustomer');
        if (window.NOIBUJS) {
          window.NOIBUJS.addCustomAttribute('customerID', payload.email);
        }
        if (cartItems.length) {
          // here we have the auth cart_id not the guest cart_id
          // if logging in with something in your cart clear the auth cart
          await dispatch('graphqlCart/clearCart', null, { root: true });
        }
        await dispatch('graphqlCart/mergeCarts', null, { root: true });
        await dispatch('graphqlCart/fetchCart', null, { root: true });
        const isStore = rootGetters['storeIp/getIsStoreIp'];
        if (
          getters.getRedirectCheckout &&
          getters.getCheckoutRedirectVersion === 'new'
        ) {
          window.location.replace(config.newCheckoutUrl);
          return;
        }
        if (
          getters.getRedirectCheckout &&
          getters.getCheckoutRedirectVersion === 'old'
        ) {
          router.push('/checkout').catch((err) => {});
          return;
        }
        if (!isStore && getters.getRedirectCheckout) {
          router.push('/checkout').catch((err) => {});
          return;
        }
        if (!isStore && !getters.getRedirectCheckout) {
          router.push({ name: 'my-account' }).catch((err) => {});
          dispatch('craftCms/closeMobileMenu', null, { root: true });
        }
      } catch (error) {
        commit('SET_ERROR', error);
      } finally {
        commit('SET_LOADING', false);
        commit('SET_REDIRECT_CHECKOUT', false);
        commit('SET_CHECKOUT_REDIRECT_VERSION', '');
      }
    },
    async logout({ commit, dispatch }) {
      try {
        commit('SET_LOADING', true);
        commit('SET_ERROR', '');
        await dispatch('graphqlCart/clearCartId', null, { root: true });
        const query = logoutQuery;
        await dispatch('fetchData', query);
        await dispatch('clearCustomer');
        router.push('/').catch((err) => {});
      } catch (error) {
        commit('SET_ERROR', error);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async fetchCustomer({ commit, dispatch }) {
      try {
        const token = Cookies.get('customer_token');
        if (!token) return;
        commit('SET_LOADING', true);
        commit('SET_ERROR', '');
        await dispatch('checkCustomerToken');
        const query = customerQuery;
        const res = await dispatch('fetchData', query);
        await dispatch('graphqlCart/createAuthCart', null, { root: true }); // TODO check we need this
        commit('SET_CUSTOMER', res.data.data.customer);
      } catch (error) {
        commit('SET_ERROR', error);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async updateCustomer({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true);
        commit('SET_ERROR', '');
        await dispatch('checkCustomerToken');
        const query = updateCustomerQuery(payload.firstName, payload.lastName);
        const res = await dispatch('fetchData', query);
        commit('SET_CUSTOMER', res.data.data.updateCustomerV2.customer);
      } catch (error) {
        commit('SET_ERROR', error);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async updateCustomerEmail({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true);
        commit('SET_ERROR', '');
        await dispatch('checkCustomerToken');
        const query = updateCustomerEmailQuery(payload.email, payload.password);
        const res = await dispatch('fetchData', query);
        commit('SET_CUSTOMER', res.data.data.updateCustomerEmail.customer);
      } catch (error) {
        commit('SET_ERROR', error);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async updateCustomerPassword({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true);
        commit('SET_ERROR', '');
        await dispatch('checkCustomerToken');
        const query = updatePasswordQuery(
          payload.password,
          payload.newPassword
        );
        await dispatch('fetchData', query);
      } catch (error) {
        commit('SET_ERROR', error);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async createCustomerAddress({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true);
        commit('SET_ERROR', '');
        await dispatch('checkCustomerToken');
        const query = createCustomerAddressQuery(payload);
        await dispatch('fetchData', query);
        const res = await dispatch('fetchData', customerQuery);
        commit('SET_CUSTOMER', res.data.data.customer);
      } catch (error) {
        commit('SET_ERROR', error);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async updateCustomerAddress({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true);
        commit('SET_ERROR', '');
        await dispatch('checkCustomerToken');
        const query = updateCustomerAddressQuery(payload);
        await dispatch('fetchData', query);
        const res = await dispatch('fetchData', customerQuery);
        commit('SET_CUSTOMER', res.data.data.customer);
      } catch (error) {
        commit('SET_ERROR', error);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async deleteCustomerAddress({ commit, dispatch }, id: number) {
      try {
        commit('SET_LOADING', true);
        commit('SET_ERROR', '');
        await dispatch('checkCustomerToken');
        const query = deleteCustomerAddressQuery(id);
        await dispatch('fetchData', query);
        const res = await dispatch('fetchData', customerQuery);
        commit('SET_CUSTOMER', res.data.data.customer);
      } catch (error) {
        commit('SET_ERROR', error);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async subscribeNewsletter({ commit, dispatch }, email: string) {
      try {
        commit('SET_LOADING', true);
        commit('SET_ERROR', '');
        await dispatch('checkCustomerToken');
        const query = subscribeNewsletterQuery(email);
        await dispatch('fetchData', query);
        await dispatch('fetchCustomer');
        return 'subscribed';
      } catch (error) {
        commit('SET_ERROR', error);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async unsubscribeNewsletter({ commit, dispatch }, email: string) {
      try {
        commit('SET_LOADING', true);
        commit('SET_ERROR', '');
        await dispatch('checkCustomerToken');
        const query = unsubscribeNewsletterQuery(email);
        await dispatch('fetchData', query);
        await dispatch('fetchCustomer');
        return 'unsubscribed';
      } catch (error) {
        commit('SET_ERROR', error);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async createCustomer({ commit, dispatch }, payload) {
      try {
        commit('SET_LOADING', true);
        commit('SET_ERROR', '');
        const query = createCustomerQuery(payload);
        await dispatch('fetchData', query);
        dispatch('login', {
          email: payload.email,
          password: payload.password
        });
      } catch (error) {
        commit('SET_ERROR', error);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async requestPasswordResetEmail({ commit, dispatch }, email: string) {
      try {
        commit('SET_LOADING', true);
        commit('SET_MESSAGE', '');
        commit('SET_ERROR', '');
        const query = requestPasswordResetEmailQuery(email);
        const res = await dispatch('fetchData', query);
        const emailSent: boolean = res.data.data.requestPasswordResetEmail;
        if (emailSent) {
          commit('SET_MESSAGE', 'Password reset email sent');
        }
      } catch (error) {
        commit('SET_ERROR', error);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async fetchOrderDetails({ commit, dispatch }, orderId: string) {
      try {
        const token = Cookies.get('customer_token');
        if (!token) return;
        commit('SET_LOADING', true);
        commit('SET_ERROR', '');
        commit('SET_CUSTOMER_ORDER', null);
        await dispatch('checkCustomerToken');
        const query = orderDetailsQuery(orderId);
        const res = await dispatch('fetchData', query);
        commit('SET_CUSTOMER_ORDER', res.data.data.customer.orders);
      } catch (error) {
        commit('SET_ERROR', error);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    closeAllAccountNav({ commit }) {
      commit('SET_SHOW_ORDERS', false);
      commit('SET_SHOW_PROFILE', false);
      commit('SET_SHOW_SHIPPING', false);
      commit('SET_SHOW_NEWSLETTER', false);
      commit('CLEAR_ERROR');
    },
    toggleShowOrders({ commit, dispatch }) {
      dispatch('closeAllAccountNav');
      commit('SET_SHOW_ORDERS', true);
      if (router.currentRoute && router.currentRoute.name === 'my-orders')
        return;
      router.push({ name: 'my-orders' }).catch((err) => {});
    },
    toggleShowProfile({ commit, dispatch }) {
      dispatch('closeAllAccountNav');
      commit('SET_SHOW_PROFILE', true);
      if (router.currentRoute && router.currentRoute.name === 'my-profile')
        return;
      router.push({ name: 'my-profile' }).catch((err) => {});
    },
    toggleShowShipping({ commit, dispatch }) {
      dispatch('closeAllAccountNav');
      commit('SET_SHOW_SHIPPING', true);
      if (router.currentRoute && router.currentRoute.name === 'my-shipping')
        return;
      router.push({ name: 'my-shipping' }).catch((err) => {});
    },
    toggleShowNewsletter({ commit, dispatch }) {
      dispatch('closeAllAccountNav');
      commit('SET_SHOW_NEWSLETTER', true);
      if (router.currentRoute && router.currentRoute.name === 'my-newsletter')
        return;
      router.push({ name: 'my-newsletter' }).catch((err) => {});
    },
    toggleEditShipping({ commit }) {
      commit('SET_EDIT_SHIPPING');
      commit('CLEAR_ERROR');
    },
    toggleEditProfile({ commit }) {
      commit('SET_EDIT_PROFILE');
      commit('CLEAR_ERROR');
    },
    clearCustomer({ commit }) {
      Cookies.remove('customer_token');
      Cookies.remove('customer_expire_time');
      Cookies.remove('customer_refresh_token');
      commit('SET_CUSTOMER', null);
      if (router.currentRoute && router.currentRoute.name === 'account') {
        router.push('/').catch((err) => {});
      }
    },
    trackOrderConfirmation({ getters }) {
      googleTagManagerEventOrderConfirmation(getters.getLastOrder);
    },
    clearError({ commit }) {
      commit('CLEAR_ERROR');
    },
    clearMessage({ commit }) {
      commit('CLEAR_MESSAGE');
    },
    setRedirectCheckout({ commit }, payload) {
      commit('SET_REDIRECT_CHECKOUT', payload);
    },
    showAccountDropdown({ commit }) {
      commit('SET_SHOW_ACCOUNT_DROPDOWN');
    },
    hideAccountDropdown({ commit }) {
      commit('CLEAR_HIDE_ACCOUNT_DROPDOWN');
    },
    setRedirectCheckoutVersion({ commit }, payload) {
      commit('SET_CHECKOUT_REDIRECT_VERSION', payload);
    }
  },
  getters: {
    isLoggedIn: (state) => !!state.customer,
    getCustomer: (state) => state.customer,
    getAccountErrors: (state) => state.error,
    getAccountMessage: (state) => state.message,
    getLoadingAccount: (state) => state.loading,
    getAvailableCountries: () => availableCountries,
    getShowOrders: (state) => state.showOrders,
    getShowProfile: (state) => state.showProfile,
    getShowShipping: (state) => state.showShipping,
    getOrderDetails: (state) => state.orderDetails,
    getShowNewsletter: (state) => state.showNewsletter,
    getShowEditProfile: (state) => state.showEditProfile,
    getShowEditShipping: (state) => state.showEditShipping,
    getRedirectCheckout: (state) => state.redirectCheckout,
    getShowAccountDropdown: (state) => state.showAccountDropdown,
    getHasAddress: (state) =>
      !!(
        state.customer &&
        state.customer.addresses &&
        state.customer.addresses.length
      ),
    getOrderHistory: (state) => {
      if (!state.customer) return [];
      return state.customer.orders.items.sort((a, b) => {
        const dateA = dayjs(a.order_date);
        const dateB = dayjs(b.order_date);
        return dateB.diff(dateA);
      });
    },
    getLastOrder: (state) => {
      if (!state.customer) return null;
      return state.customer.orders.items[
        state.customer.orders.items.length - 1
      ];
    },
    getCheckoutRedirectVersion: (state) => state.checkoutRedirectVersion
  }
};
