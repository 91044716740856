import { MutationTree } from 'vuex';
import * as types from './mutation-types';
import remove from 'lodash-es/remove';
import CustomColorState from './types/CustomColorState';

const mutations: MutationTree<CustomColorState> = {
  [types.SET_SHOW_BIKE] (state) {
    state.showBike = !state.showBike;
  },
  [types.SET_BUNDLE_OPTIONS] (state, options) {
    state.bundleOptions = options;
  },
  [types.CLEAR_BUNDLE_OPTIONS] (state) {
    state.bundleOptions = [];
  },
  [types.SET_BUNDLE_OPTION] (state, bundleOption) {
    const optionToUpdate = state.bundleOptions.find(
      option => option.bundleOptionId === bundleOption.bundleOptionId
    );
    if (optionToUpdate) {
      remove(state.bundleOptions, { bundleOptionId: bundleOption.bundleOptionId });
    }
    state.bundleOptions.push(bundleOption);
  },
  [types.CLEAR_BUNDLE_OPTION] (state, bundleOption) {
    const optionToClear = state.bundleOptions.find(
      option => option.bundleOptionId === bundleOption.bundleOptionId
    );
    if (!optionToClear) return;
    optionToClear.optionId = null;
    optionToClear.images = null;
    optionToClear.swatch = null;
    optionToClear.price = null;
    optionToClear.name = null;
    optionToClear.slug = null;
  },
  [types.SET_GRADIENT_ANGLE] (state, angle) {
    state.gradientAngle = angle;
  },
  [types.CLEAR_GRADIENT_ANGLE] (state) {
    state.gradientAngle = null;
  },
  [types.SET_COCKPIT_MATCH] (state, payload) {
    state.cockpitMatch = payload;
  },
  [types.CLEAR_COCKPIT_MATCH] (state) {
    state.cockpitMatch = false;
  }
};

export default mutations;
;
