import axios from 'axios';
import config from 'config';
import uniq from 'lodash-es/uniq';
import productPriceQuery from './magento-queries/product-price';

export const trendingStore = {
  namespaced: true,
  state: {
    error: '',
    loading: false,
    trendingPrices: null
  },
  mutations: {
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    SET_TRENDING_PRICES(state, payload) {
      state.trendingPrices = payload;
    }
  },
  actions: {
    async fetchPricesFromSkus({ commit }, skus) {
      if (!skus || !skus.length) return;
      const url = config.m2GraphqlUrl;
      const query = productPriceQuery(uniq(skus));
      try {
        commit('SET_LOADING', true);
        const res = await axios.post(url, query);
        if (res.data.errors && res.data.errors.length) {
          console.error(res.data.errors[0]);
          throw new Error('Error with graphql api');
        }
        commit('SET_TRENDING_PRICES', res.data.data);
      } catch (error) {
        commit('SET_ERROR', error);
      } finally {
        commit('SET_LOADING', false);
      }
    }
  },
  getters: {
    getTrendingOriginalPrice: (state, getters, rootState, rootGetters) => {
      const activeTrending = rootGetters['craftCms/activeTrending'];
      const trendingPrices = state.trendingPrices;
      if (!trendingPrices) return null;
      const item = trendingPrices.products.items.find(
        (x) => x.sku === activeTrending.sku
      );
      if (!item) return null;
      return item.price_range.maximum_price.regular_price.value;
    },
    getTrendingFinalPrice: (state, getters, rootState, rootGetters) => {
      const activeTrending = rootGetters['craftCms/activeTrending'];
      const trendingPrices = state.trendingPrices;
      if (!trendingPrices) return null;
      const item = trendingPrices.products.items.find(
        (x) => x.sku === activeTrending.sku
      );
      if (!item) return null;
      return item.price_range.maximum_price.final_price.value;
    },
    getTrendingSpecialPrice: (state, getters) => {
      const trendingPrices = state.trendingPrices;
      if (!trendingPrices) return null;
      if (getters.getTrendingOriginalPrice > getters.getTrendingFinalPrice) {
        return getters.getTrendingFinalPrice;
      }
      return 0;
    }
  }
};
