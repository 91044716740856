export const SN_CUSTOM_COLOR = 'bikes';
export const SET_SHOW_BIKE = SN_CUSTOM_COLOR + '/SET_SHOW_BIKE';
export const SET_BUNDLE_OPTIONS = SN_CUSTOM_COLOR + '/SET_BUNDLE_OPTIONS';
export const SET_BUNDLE_OPTION = SN_CUSTOM_COLOR + '/SET_BUNDLE_OPTION';
export const CLEAR_BUNDLE_OPTION = SN_CUSTOM_COLOR + '/CLEAR_BUNDLE_OPTION';
export const CLEAR_BUNDLE_OPTIONS = SN_CUSTOM_COLOR + '/CLEAR_BUNDLE_OPTIONS';
export const SET_GRADIENT_ANGLE = SN_CUSTOM_COLOR + '/SET_GRADIENT_ANGLE';
export const CLEAR_GRADIENT_ANGLE = SN_CUSTOM_COLOR + '/CLEAR_GRADIENT_ANGLE';
export const SET_COCKPIT_MATCH = SN_CUSTOM_COLOR + '/SET_COCKPIT_MATCH';
export const CLEAR_COCKPIT_MATCH = SN_CUSTOM_COLOR + '/CLEAR_COCKPIT_MATCH';
