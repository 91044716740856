export default function productPriceQuery(skus) {
  return {
    query: `
      query ($skus: [String]) {
        products(filter: { sku: { in: $skus } }) {
          items {
            sku
            price_range {
              maximum_price {
                regular_price {
                  value
                  currency
                }
                final_price {
                  value
                  currency
                }
              }
            }
          }
        }
      }
    `,
    variables: {
      skus
    }
  };
}
