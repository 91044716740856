export default function query(site, slug) {
    return {
      query: `
        query ($site: [String], $slug: [String]) {
          entry(site: $site, slug: $slug) {
            ... on helpCentre_helpCentre_Entry {
              id
              slug
              title
              metaTitle
              metaDescription
              metaKeywords
              helpMenu {
                ... on helpMenu_helpSection_BlockType {
                  id
                  heading
                  externalLinks {
                    linkText
                    linkUrl
                  }
                  ribbleLinks {
                    id
                    title
                    slug
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        site,
        slug
      }
    };
  }
