const RibbleHome = () => import(/* webpackChunkName: "vsf-home" */ 'theme/pages/RibbleHome.vue');
const PageNotFound = () => import(/* webpackChunkName: "vsf-not-found" */ 'theme/pages/PageNotFound.vue');
const Maintenance = () => import(/* webpackChunkName: "vsf-not-found" */ 'theme/pages/Maintenance.vue');
const ErrorPage = () => import(/* webpackChunkName: "vsf-error" */ 'theme/pages/Error.vue');
const Product = () => import(/* webpackChunkName: "vsf-product" */ 'theme/pages/Product.vue');
const Category = () => import(/* webpackChunkName: "vsf-category" */ 'theme/pages/Category.vue');
const CategoryList = () => import(/* webpackChunkName: "vsf-category" */ 'theme/pages/CategoryList.vue');
const CmsPage = () => import(/* webpackChunkName: "vsf-cms" */ 'theme/pages/CmsPage.vue');
const Checkout = () => import(/* webpackChunkName: "vsf-checkout" */ 'theme/pages/Checkout.vue');
const Compare = () => import(/* webpackChunkName: "vsf-compare" */ 'theme/pages/Compare.vue');
const RibbleMyOrder = () => import(/* webpackChunkName: "vsf-my-order" */ 'theme/pages/RibbleMyOrder.vue');
const RibbleMyAccount = () => import(/* webpackChunkName: "vsf-my-account" */ 'theme/pages/RibbleMyAccount.vue');
const Static = () => import(/* webpackChunkName: "vsf-static" */ 'theme/pages/Static.vue');
const BikeReview = () => import(/* webpackChunkName: 'bike-review' */ 'theme/pages/BikeReview.vue');
const PWA = () => import(/* webpackChunkName: "vsf-pwa" */ 'theme/pages/PWA');
const CreatePassword = () => import(/* webpackChunkName: "vsf-reset-password" */ 'theme/pages/CreatePassword.vue');
const CycleToWorkQuote = () => import(/* webpackChunkName: "vsf-c2w-quote" */ 'theme/pages/cycletowork/Quote.vue');
const SitemapCategories = () => import(/* webpackChunkName: "vsf-sitemap" */ 'theme/pages/sitemap/SitemapCategories.vue');
const SitemapProducts = () => import(/* webpackChunkName: "vsf-sitemap" */ 'theme/pages/sitemap/SitemapProducts.vue');
const Search = () => import(/* webpackChunkName: "vsf-search" */ 'theme/pages/Search.vue');
const AnniversaryPage = () => import(/* webpackChunkName: "AnniversaryPage" */'theme/pages/AnniversaryPage.vue');
const PageBuilder = () => import(/* webpackChunkName: "PageBuilder" */'theme/pages/PageBuilder.vue');
const CraftBlogPost = () => import(/* webpackChunkName: "CraftBlogPost" */'theme/pages/craftblog/Post.vue');
const PostListing = () => import(/* webpackChunkName: "CraftBlogPostListing" */'theme/pages/craftblog/PostListing.vue');
const PostTagListing = () => import(/* webpackChunkName: "CraftBlogPostTagListing" */'theme/pages/craftblog/PostTagListing.vue');
const PostCategoryListing = () => import(/* webpackChunkName: "CraftBlogPostCategoryListing" */'theme/pages/craftblog/PostCategoryListing.vue');
const HelpListing = () => import(/* webpackChunkName: "HelpListing" */'theme/pages/help-centre/PostListing.vue');
const HelpPost = () => import(/* webpackChunkName: "HelpLPost" */'theme/pages/help-centre/Post.vue');

const ConfiguratorPage = () => import(/* webpackChunkName: 'configurator-page' */ 'theme/pages/configurator/ConfiguratorPage.vue');
const RibbleMyBuild = () =>
  import(
    /* webpackChunkName: 'bike-builder-my-build' */ 'theme/pages/configurator/MyBuild.vue'
  );
const RibbleUpsell = () => import(/* webpackChunkName: 'ribble-upsell' */ 'theme/pages/RibbleUpsell.vue');

let routes = [
  { name: 'home', path: '/', component: RibbleHome },
  { name: 'search', path: '/search', component: Search },
  { name: 'checkout', path: '/checkout', component: Checkout },
  {
    name: 'my-account',
    path: '/my-account',
    component: RibbleMyAccount,
    props: { title: 'My Account' }
  },
  {
    name: 'my-profile',
    path: '/my-account/profile',
    component: RibbleMyAccount,
    props: { title: 'My Profile' }
  },
  {
    name: 'my-orders',
    path: '/my-account/orders',
    component: RibbleMyAccount,
    props: { title: 'My Orders' }
  },
  {
    name: 'my-shipping',
    path: '/my-account/shipping',
    component: RibbleMyAccount,
    props: { title: 'My Shipping' }
  },
  {
    name: 'my-newsletter',
    path: '/my-account/newsletter',
    component: RibbleMyAccount,
    props: { title: 'My Newsletter' }
  },
  {
    name: 'my-cycle-to-work',
    path: '/my-account/cycle-to-work',
    component: RibbleMyAccount,
    props: { title: 'Cycle To Work' }
  },
  {
    name: 'my-order',
    path: '/my-account/orders/:orderId',
    component: RibbleMyOrder,
    props: { title: 'Order' }
  },
  {
    name: 'contact',
    path: '/contact',
    component: Static,
    props: { page: 'contact', title: 'Contact Us' }
  },
  {
    name: 'compare',
    path: '/compare',
    component: Compare,
    props: { title: 'Compare Products' }
  },
  {
    name: 'error',
    path: '/error',
    component: ErrorPage,
    meta: { layout: 'Default' }
  },
  { name: 'virtual-product', path: '/p/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'bundle-product', path: '/p/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'simple-product', path: '/p/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  {
    name: 'ribble_bikebuilder-product',
    path: '/p/:parentSku/:slug',
    component: Product
  }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  {
    name: 'bike-review',
    path: '/leave-a-bike-review/:sku',
    component: BikeReview
  },
  {
    name: 'downloadable-product',
    path: '/p/:parentSku/:slug',
    component: Product
  }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'grouped-product', path: '/p/:parentSku/:slug', component: Product }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  {
    name: 'configurable-product',
    path: '/p/:parentSku/:slug/:childSku',
    component: Product
  }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  {
    name: 'product',
    path: '/p/:parentSku/:slug/:childSku',
    component: Product
  }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  { name: 'category-list', path: '/cl/:slug', component: CategoryList },
  { name: 'category', path: '/c/:slug', component: Category },
  { name: 'cms-page', path: '/i/:slug', component: CmsPage },
  { name: 'page-not-found', path: '*', component: PageNotFound },
  {
    name: 'maintenance',
    path: '/error/503',
    meta: { layout: 'Empty' },
    component: Maintenance
  },
  {
    name: 'ribble_bikebuilder-product-amp',
    path: '/amp/p/:parentSku/:slug',
    component: Product,
    meta: { layout: 'minimal' }
  }, // :sku param can be marked as optional with ":sku?" (https://github.com/vuejs/vue-router/blob/dev/examples/route-matching/app.js#L16), but it requires a lot of work to adjust the rest of the site
  {
    name: 'create-password',
    path: '/create-password',
    component: CreatePassword
  },
  {
    name: 'cycleToWorkQuote',
    path: '/cycletowork/quote/',
    meta: { layout: 'Empty' },
    component: CycleToWorkQuote
  },
  {
    name: 'sitemap-categories',
    path: '/catalog/seo_sitemap/category/',
    component: SitemapCategories
  },
  {
    name: 'sitemap-products',
    path: '/catalog/seo_sitemap/product/',
    component: SitemapProducts
  },
  { name: 'craft-page', path: '/i/:uri', component: PageBuilder },
  {
    name: 'configurator',
    path: '/:bike/build/',
    meta: { layout: 'Default' },
    props: true,
    component: ConfiguratorPage
  },
  {
    name: 'myBuild',
    path: '/mybuild/',
    props: { title: 'My Build' },
    meta: { layout: 'Default' },
    component: RibbleMyBuild
  },
  {
    name: 'ribbleUpsell',
    path: '/:bike/review/',
    props: { title: 'Review and Buy' },
    meta: { layout: 'Default' },
    component: RibbleUpsell
  },
  {
    name: '125-anniversary',
    path: '/125-anniversary/',
    meta: { layout: 'Default' },
    component: AnniversaryPage
  },
  {
    name: 'PWA',
    path: '/landing',
    alias: '/pwa.html',
    meta: { layout: 'empty' },
    props: true,
    component: PWA
  },
  {
    name: 'myRibble',
    path: '/my-ribble/',
    props: { title: 'My Ribble', page: 'My Ribble' },
    component: () =>
      import(/* webpackChunkName: 'MyRibble' */ 'theme/pages/MyRibble.vue')
  },
  {
    name: 'blog',
    path: '/blog/:slug',
    meta: { layout: 'Default' },
    component: CraftBlogPost
  },
  {
    name: 'post-listing',
    path: '/blog/',
    meta: { layout: 'Default' },
    component: PostListing
  },
  {
    name: 'post-tag-listing',
    path: '/blog/tag/:slug/',
    meta: { layout: 'Default' },
    component: PostTagListing
  },
  {
    name: 'post-category-listing',
    path: '/blog/category/:slug/',
    meta: { layout: 'Default' },
    component: PostCategoryListing
  },
  {
    name: 'help-centre',
    path: '/help-centre-2/',
    meta: { layout: 'Default' },
    component: HelpListing
  },
  {
    name: 'help-article',
    path: '/help-centre-2/:slug',
    meta: { layout: 'Default' },
    component: HelpPost
  }
];

export default routes;
