type ESWPrice = {
  price: number;
  type: string;
  sku: string;
};

function formatDataProduct(priceData: []) {
  const array = priceData.map((item: ESWPrice) => {
    return `{
        sku: "${item.sku}"
        type: "${item.type}"
      }`;
  });
  return `[${array}]`;
}

function formatDataCart(priceData: []) {
  const array = priceData.map((item: ESWPrice) => {
    return `{
        sku: "${item.sku}"
        type: "${item.type}"
        price: ${item.price}
      }`;
  });
  return `[${array}]`;
}

export function eswCartQuery(pricesToCheck) {
  return {
    query: `
      query {
        eShopWorldPriceConvert(prices: ${formatDataCart(pricesToCheck)})
        {
          sku
          type
          price
        }
      }
  `
  };
}

export function eswProductQuery(pricesToCheck) {
  return {
    query: `
      query {
        eShopWorldPriceConvert(prices: ${formatDataProduct(pricesToCheck)})
        {
          sku
          type
          price
        }
      }
  `
  };
}
