import axios from 'axios';
import config from 'config';
import {
  certificateMutation,
  certificateMutationC2W
} from './magento-queries/certificates';

type Mutation = {
  query: string;
  variables: {
    file: any;
    name: any;
    certificate_id: any;
  };
};

async function fetchGraphqlData(query: Mutation) {
  const url = config.m2GraphqlUrl;
  try {
    const res = await axios.post(url, query);
    if (res.data.errors && res.data.errors.length) {
      console.error(res.data.errors[0]);
      throw new Error('Error with magento graphql api');
    }
    return res.data.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export const uploadCertificateStore = {
  namespaced: true,
  state: {
    uploadPath: '',
    uploading: false
  },
  mutations: {
    SET_UPLOAD_PATH(state, uploadPath) {
      state.uploadPath = uploadPath;
    },
    SET_UPLOADING(state, uploading) {
      state.uploading = uploading;
    }
  },
  actions: {
    async uploadCertificate({ commit }, payload) {
      if (!payload.certificateId) return;
      try {
        commit('SET_UPLOADING', true);
        const mutation =
          payload.C2wMethod === 'new'
            ? certificateMutationC2W(
                payload.base64,
                payload.fileName,
                payload.certificateId
              )
            : certificateMutation(
                payload.base64,
                payload.fileName,
                payload.certificateId
              );

        const res = await fetchGraphqlData(mutation);
        if (res && res.uploadC2wPdf.path) {
          commit('SET_UPLOAD_PATH', res.uploadC2wPdf.path);
        }
      } catch (error) {
        console.error(error);
      } finally {
        commit('SET_UPLOADING', false);
      }
    }
  },
  getters: {
    getUploadPath: (state) => {
      return state.uploadPath;
    },
    getUploading: (state) => {
      return state.uploading;
    }
  }
};
