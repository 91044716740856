export function certificateQuery(certificate_id) {
  return {
    query: `
      query ($certificate_id: Int) {
        c2wPdfList(certificate_id: $certificate_id) {
          certificate_pdf {
            id
            pdf_status
          }
        }
      }
    `,
    variables: {
      certificate_id,
    },
  };
}

export function certificateMutation(file, name, certificate_id) {
  return {
    query: `
      mutation ($file: String, $name: String, $certificate_id: Int) {
        uploadPdf(
          input: { file: $file, name: $name, certificate_id: $certificate_id }
        ) {
          path
        }
      }
    `,
    variables: {
      file,
      name,
      certificate_id,
    },
  };
}


export function certificateMutationC2W(file, name, certificate_id) {
  return {
    query: `
      mutation ($file: String, $name: String, $certificate_id: Int) {
        uploadC2wPdf(
          input: { file: $file, name: $name, certificate_id: $certificate_id }
        ) {
          path
        }
      }
    `,
    variables: {
      file,
      name,
      certificate_id,
    },
  };
}

