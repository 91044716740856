import axios from 'axios';
import config from 'config';

const query = `
query 
{ 
         isNewCheckoutEnabled
}
`;

const m2GraphqlUrl = config.m2GraphqlUrl;

export const newCheckoutStore = {
  namespaced: true,
  state: {
    error: '',
    newCheckout: false
  },
  mutations: {
    SET_NEW_CHECKOUT_DATA(state, newCheckout) {
      state.newCheckout = newCheckout;
    },
    SET_ERROR(state, error) {
      state.error = error;
    }
  },
  actions: {
    async fetchNewCheckout({ commit }) {
      try {
        const magentoRes = await axios.post(m2GraphqlUrl, {
          query
        });
        
        const magentoResData = magentoRes.data && magentoRes.data.data ? magentoRes.data.data.isNewCheckoutEnabled : 'error';
        if (magentoResData==='error') {
          throw new Error('Error fetching store config');
        }


        commit('SET_NEW_CHECKOUT_DATA', magentoResData);
      } catch (error) {
        console.error(error);
        const e = "Error fetching new chekcout conig from Magento";
        commit('SET_NEW_CHECKOUT_DATA', false);
        commit('SET_ERROR', e);
      }
    }
  },
  getters: {
    getNewStoreConfig: state => state.newCheckout,
  }
};
