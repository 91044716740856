import { MutationTree } from 'vuex';
import * as types from './mutation-types';
import PreSpec from './types/PreSpec';
import PreSpecState from './types/PreSpecState';

const mutations: MutationTree<PreSpecState> = {
  [types.SET_PRE_SPEC] (state, preSpec: PreSpec) {
    if (typeof preSpec === 'undefined') {
      return;
    }
    state.preSpec = preSpec;
  },
  [types.CLEAR_PRE_SPEC] (state) {
    state.preSpec = null;
  },
  [types.SET_PRE_SPECS] (state, preSpecs: [PreSpec]) {
    state.preSpecs = preSpecs;
  },
  [types.SET_PRE_SPEC_PRODUCTS] (state, preSpecs) {
    state.preSpecProducts = preSpecs;
  },
  [types.SET_SIBLING_PRODUCTS] (state, products) {
    state.siblingProducts = products;
  },
  [types.SET_ERROR] (state, error) {
    state.error = error;
  }
}

export default mutations;
