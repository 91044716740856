import { StorageManager } from '@vue-storefront/core/lib/storage-manager';
import { Logger } from '@vue-storefront/core/lib/logger';

export const brexitStore = {
  namespaced: true,
  actions: {
    set (context, { brexitCode, value, description }) {
      const brexitCollection = StorageManager.get('brexit');
      brexitCollection.setItem(brexitCode, {
        code: brexitCode,
        created_at: new Date(),
        value: value,
        description: description
      }).catch((reason) => {
        Logger.error(reason); // it doesn't work on SSR
      })
    },

    unset (context, { brexitCode }) {
      const brexitCollection = StorageManager.get('brexit');
      brexitCollection.removeItem(brexitCode).catch((reason) => {
        Logger.error(reason); // it doesn't work on SSR
      })
    },

    check (context, { brexitCode }) {
      const brexitCollection = StorageManager.get('brexit');
      return brexitCollection.getItem(brexitCode).catch((reason) => {
        Logger.error(reason); // it doesn't work on SSR
      })
    }
  }
}
