<template>
  <div class="productColorSwitcher" v-if="children">
    <div :key="variation.id" v-for="variation in children">
      <div v-if="variation.attribute_code.match(/color|colour/)">
        <button
          v-for="option in variation.values"
          :key="option.id"
          :aria-label="$t('Select color {label}', {label: option.label})"
          class="mr6 mb3 bg-cl-transparent brdr-1 brdr-cl-transparent :brdr-cl-bg-primary relative inline-flex pointer color"
          @mouseover="$emit('mouseover', option)"
        >
          <span class="absolute brdr-1 brdr-cl-primary block color-inside"
                :style="colorFrom(option.label)"
          />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import config from 'config'
import filterMixin from 'theme/mixins/filterMixin.ts'
export default {
  mixins: [filterMixin],
  methods: {
    colorFrom (label) {
      if (!label) return ''
      if (config.products.colorMappings) {
        if (typeof config.products.colorMappings[label] !== 'undefined') {
          label = config.products.colorMappings[label]
        }
      }
      if (label.indexOf('/') >= 0) label = label.replace('/', ',') // to be honest - this is a hack for colors like "ink/white"
      if (label && label.toString().indexOf(',') >= 0) {
        return 'background: linear-gradient(' + label + ')'
      } else {
        return 'background-color: ' + label
      }
    }
  },
  props: {
    children: {
      default: null,
      type: Array
    }
  }
};
</script>

<style lang="scss" scoped>
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';
  $color-active: black;

  .color {
    width: 26px;
    height: 26px;
    border-radius: 3px;
    &.active {
      border-color: $color-active;
    }
  }

  .color-inside {
    border-radius: 3px;
    width: 20px;
    height: 20px;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%)
  }
</style>
