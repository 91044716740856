import assets from './assets-query';
import heroBlock from './heroBlock';
import assetsCta from './assets-cta-query';

const pageBuilderBlocks = `
pageBuilder {
  ${heroBlock}
  ... on pageBuilder_cta_BlockType {
    id
    typeHandle
    ctaText
    ctaUrl
    style
    width
    position
    externalUrl
  }
  ... on pageBuilder_headingBlock_BlockType {
    id
    width
    style
    textColour
    typeHandle
    heading
  }
  ... on pageBuilder_headingDouble_BlockType {
    id
    width
    style
    typeHandle
    heading
    headingTwo
  }
  ... on pageBuilder_textBlock_BlockType {
    id
    width
    style
    textColour
    backgroundColour
    typeHandle
    text
  }
  ... on pageBuilder_textByImage_BlockType {
    id
    width
    backgroundColour
    textColour
    typeHandle
    text
    heading
    textPosition
    imagePosition
    imageWidth
    insetImage
    imageWidthMobile
    cta {
      ctaText
      ctaUrl
      position
      style
    }
    image {
      ${assets}
    }
  }
  ... on pageBuilder_textByVideo_BlockType {
    id
    width
    backgroundColour
    textColour
    typeHandle
    text
    heading
    videoPosition
    cta {
      ctaText
      ctaUrl
      position
      style
    }
    vimeoUrl
  }
  ... on pageBuilder_imageGrid_BlockType {
    id
    width
    typeHandle
    imagesLeft {
      ${assets}
    }
    imagesRight {
      ${assets}
    }
  }
  ... on pageBuilder_carousel_BlockType {
    id
    width
    height
    modal
    typeHandle
    imageAspectRatio
    imgAutox600: images {
      id
      url(transform: "imgAutox600")
    }
    img1000xAuto: images {
      id
      url(transform: "img1000xAuto")
    }
    img500x1000: images {
      id
      url(transform: "img500x1000")
    }
    img500x500: images {
      id
      url(transform: "img500x500")
    }
  }
  ... on pageBuilder_textOnImage_BlockType {
    id
    width
    typeHandle
    heading
    text
    textPosition
    image {
      ${assets}
    }
  }
  ... on pageBuilder_quote_BlockType {
    id
    typeHandle
    textColour
    backgroundColour
    text
    quotedBy
  }
  ... on pageBuilder_image_BlockType {
    id
    width
    typeHandle
    backgroundColour
    imageUrl
    image {
      ${assets}
    }
    imageMobile {
      ${assets}
    }
  }
  ... on pageBuilder_video_BlockType {
    id
    typeHandle
    vimeoUrl
    useVimeo
    hasControls
    autoplay
    backgroundColour
    fallbackImage {
      ... on pageBuilder_Asset {
        id
        url
      }
    }
    video {
      ${assets}
    }
  }
  ... on pageBuilder_divider_BlockType {
    id
    width
    divider
    typeHandle
    backgroundColour
  }
  ... on pageBuilder_accordionMenu_BlockType {
    id
    typeHandle
    heading
    backgroundColour
    menuItems {
      menuHeading
      menuText
    }
  }
  ... on pageBuilder_spacer_BlockType {
    id
    spacer
    typeHandle
    backgroundColour
  }
  ... on pageBuilder_instagramCarousel_BlockType {
    id
    sku
    width
    typeHandle
    backgroundColour
  }
  ... on pageBuilder_trustPilot_BlockType {
    id
    typeHandle
    backgroundColour
  }
  ... on pageBuilder_blogCarousel_BlockType {
    id
    typeHandle
    backgroundColour
  }
  ... on pageBuilder_imageTextGrid_BlockType {
    id
    typeHandle
    textColour
    backgroundColour
    width
    grid {
      ... on grid_BlockType {
        id
        heading
        text
        imagePosition
        imageWidth
        imageWidthMobile
        cta {
          ctaText
          ctaUrl
          position
          style
        }
        image {
          ${assets}
        }
      }
    }
  }
  ... on pageBuilder_assetGridStacked_BlockType {
    id
    width
    height
    typeHandle
    backgroundColour
    imageAspectRatio
    rowOneAssets {
      ${assetsCta}
    }
    rowTwoAssets {
      ${assetsCta}
    }
    rowThreeAssets {
      ${assetsCta}
    }
  }
  ... on pageBuilder_linkList_BlockType {
    id
    typeHandle
    headingListOne
    linkListFour {
      linkText
      linkUrl
    }
    headingListTwo
    linkListOne {
      linkText
      linkUrl
    }
    headingListThree
    linkListThree {
      linkText
      linkUrl
    }
    headingListFour
    linkListTwo {
      linkText
      linkUrl
    }
  }
  ... on pageBuilder_menuBlock_BlockType {
    id
    typeHandle
    backgroundColour
    textColour
    menuSection {
      ... on menuSection_BlockType {
        id
        sectionHeading
        sectionLinks {
          linkText
          linkUrl
        }
      }
    }
  }
  ... on pageBuilder_emailSignUp_BlockType {
    id
    typeHandle
    text
    source
  }
}
`;

export default pageBuilderBlocks;
