export default function categoryMenuQuery (slug) {
  return {
    query: `
        query ($slug: [String]) {
          category(slug: $slug) {
            slug
            ... on products_Category {
              categoryMenu {
                ... on categoryMenu_menu_BlockType {
                  id
                  menuLink
                  menuHeading
                  menuImage {
                    ... on categories_Asset {
                      url(transform: "img200xauto")
                    }
                  }
                }
              }
            }
          }
        }
      `,
    variables: {
      slug
    }
  };
}
