<template>
  <button @click="isOnWishlist ? removeProductFromWhishList(product) : addProductToWhishlist(product)" class="p0 inline-flex middle-xs bg-cl-transparent brdr-none h5 pointer cl-secondary" type="button" data-testid="addToWishlist">
    <slot>
      <i class="pr5 material-icons">{{ favoriteIcon }}</i>
      <template v-if="!isOnWishlist">
        {{ $t('Add To Wishlist') }}
      </template>
      <template v-else>
        {{ $t('Remove') }}
      </template>
    </slot>
  </button>
</template>

<script>
import { IsOnWishlist } from '@vue-storefront/core/modules/wishlist/components/IsOnWishlist'
import { AddToWishlist } from '@vue-storefront/core/modules/wishlist/components/AddToWishlist'
import { RemoveFromWishlist } from '@vue-storefront/core/modules/wishlist/components/RemoveFromWishlist'
import i18n from '@vue-storefront/i18n'
import { htmlDecode } from '@vue-storefront/core/lib/store/filters'
import {mapGetters} from 'vuex';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';

export default {
  mixins: [ IsOnWishlist, AddToWishlist, RemoveFromWishlist ],
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn'
    }),
    favoriteIcon () {
      return this.isOnWishlist ? 'favorite' : 'favorite'
    }
  },
  methods: {
    addProductToWhishlist (product) {
      if (this.isLoggedIn) {
        this.add(product)
      } else {
        this.$store.commit('ui/setAuthElem', 'login')
        this.$bus.$emit('modal-show', 'modal-signup')
      }
      this.$bus.$on('logged-in', () => this.add(product))
    },
    add (product) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message: i18n.t('Product {productName} has been added to wishlist!', {productName: htmlDecode(product.name)}),
        action1: {label: i18n.t('OK')}
      }, {root: true})
      this.addToWishlist(product)
    },
    removeProductFromWhishList (product) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message: i18n.t('Product {productName} has been removed from wishlist!', { productName: htmlDecode(product.name) }),
        action1: { label: i18n.t('OK') }
      }, { root: true })
      this.removeFromWishlist(product)
    }
  }
}
</script>
