// @ts-nocheck
import { HasuraError } from './bike.types';
import { ActionContext, ActionTree, GetterTree } from 'vuex';
import { RootState } from './root.types';

export interface ColorStoreActions extends ActionTree<ColorState, RootState> {
  fetchColor: (context: ActionContext<ColorState, RootState>) => Promise<void>;
}
export interface PriceRange {
  minimum_price: {
    regular_price: {
      value: number;
    };
  };
}
export interface OptionItem {
  options: OptionItem[];
  required: boolean;
  section: number;
  section_slug: string | null;
  title: string;
  uid: string;
}
export interface AxiosColorResponse {
  data: {
    products: Color;
  };
  errors?: HasuraError[];
}
export interface Color {
  items: OptionItem[];
  name: string;
  price_range: PriceRange;
  sku: string;
  uid: string;
}
export interface ColorGetters extends GetterTree<ColorState, RootState> {
  getColors: (state: ColorState) => Color | null;
  getLoading: (state: ColorState) => boolean;
}
export interface ColorState {
  color: Color | null;
  loading: boolean;
  errorMessage: string;
}
export enum ColorOptionType {
  'PaintFinish' = 'Paint Finish',
  'FrameColour' = 'Frame Colour',
  'DecalColour' = 'Decal Colour',
  'GradientColour' = 'Gradient Colour',
  'GradientAngle' = 'Gradient Angle',
  'AccentColour1' = 'Downtube Logo',
  'AccentColour2' = 'Decal Colour'
}
export enum GradientOptionType {
  'Gradient Angle 115' = 'Diagonal',
  'Gradient Angle 0' = 'Horizontal'
}
export const allColourOptions = [
  ColorOptionType.FrameColour,
  ColorOptionType.GradientColour,
  ColorOptionType.DecalColour,
  ColorOptionType.AccentColour1,
  ColorOptionType.AccentColour2,
  'Top Tube and Fork Logo',
  'Frame and Fork Logo'
];
export const titleMap = {
  [ColorOptionType.FrameColour]: 'frameColor',
  [ColorOptionType.DecalColour]: 'decalColor',
  [ColorOptionType.AccentColour2]: 'accentColor1',
  [ColorOptionType.AccentColour2]: 'decalColor',
  [ColorOptionType.GradientColour]: 'gradientColor',
  'Top Tube and Fork Logo':'decalColor',
  'Frame and Fork Logo':'decalColor',
  'Downtube Logo':'accentColor1'
}
export interface ColorStore {
  namespaced: boolean;
  state: ColorState;
  actions: ColorStoreActions;
  getters: ColorGetters;
  mutations: {
    SET_COLOR: (state: ColorState, color: Color) => void;
    SET_LOADING: (state: ColorState, loading: boolean) => void;
    SET_ERROR_MESSAGE: (state: ColorState, errorMessage: string) => void;
  };
}
