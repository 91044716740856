import axios from 'axios';
import config from 'config';

export function formatToGraphql(arrayObjectData) {
  if (!arrayObjectData || !arrayObjectData.length) return;

  const cleaned = JSON.stringify(arrayObjectData, null, 2);
  return cleaned.replace(/^[\t ]*"(?:[^:\n\r\\]|\\[^])*":/gm, function (match) {
    return match.replace(/"/g, '');
  });
}

function shareCodeMutation(bikeOptions) {
  return `
    mutation {
      generateBuildCode(
      selectedItems: ${bikeOptions}
    ) {
      code
    }
  }
`;
}

export const shareStore = {
  namespaced: true,
  state: {
    code: null,
    loading: false,
    errorMessage: ''
  },
  mutations: {
    SET_CODE(state, code) {
      state.code = code;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_ERROR_MESSAGE(state, errorMessage) {
      state.errorMessage = errorMessage;
    }
  },
  actions: {
    async fetchCode({ commit }, payload) {
      try {
        commit('SET_LOADING', true);
        commit('SET_ERROR_MESSAGE', '');
        const bikeItems = formatToGraphql(payload.selectedItems);
        const query = shareCodeMutation(bikeItems);
        const res = await axios.post(config.m2GraphqlUrl, { query });
        commit('SET_CODE', res.data.data.generateBuildCode.code);
      } catch (error) {
        console.error(error);
        if (error instanceof Error) {
          commit('SET_ERROR_MESSAGE', error.message);
        }
      } finally {
        commit('SET_LOADING', false);
      }
    }
  },
  getters: {
    getCode: (state) => state.code
  }
};
