import axios from 'axios'
import config from 'config'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export function getPathForStaticPage (path: string) {
  const { storeCode } = currentStoreView()
  const isStoreCodeEquals = storeCode === config.defaultStoreCode

  return isStoreCodeEquals ? `/i${path}` : path
}

export async function getCmsData(query, variables = {}) {
  const url = 'https://cms.ribblecycles.co.uk.cfstack.com/api';
  try {
    const res = await axios.post(url, { query, variables });
    if (res.data.errors && res.data.errors.length) {
      console.error(res.data.errors[0]);
      throw new Error('Error with graphql api');
    }
    return res.data.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

function stripTrailingSlash(str) {
  return str.endsWith('/') ?
      str.slice(0, -1) :
      str;
};

function stripNull(str) {
  if (str.endsWith('null/')) {
    return str.slice(0, -5);
  }
  if (str.endsWith('null')) {
    return str.slice(0, -4);
  }
  return str;
};

function stripLeadingSlash(str) {
  return str.startsWith('/') ?
      str.slice(1) :
      str;
};

export function cleanUrl(url) {
  const cleanUrl = stripNull(url);
  return stripTrailingSlash(stripLeadingSlash(cleanUrl));
};

export async function fetchEswGraphqlData(query, country, currency) {
  const url = config.esw.magentoGraphqlUrl;
  if (!url) {
    throw new Error('No graphql url provided');
  }
  try {
    const res = await axios.post(url, query, {
      headers: {
        'Content-Type': 'application/json',
        'Content-Currency': currency,
        'ESW-Country': country
      }
    });
    if (res.data.errors && res.data.errors.length) {
      console.error(res.data.errors[0]);
      throw new Error('Error with magento graphql api');
    }
    return res.data.data;
  } catch (error) {
    console.error('error');
    return null;
  }
}
